import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ElementControlFormWrapperComponent} from '../../sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';

@Component({
  selector: 'primary-radio',
  templateUrl: 'primary-radio.component.html',
  styleUrls: ['primary-radio.component.scss']
})
export class PrimaryRadioComponent extends ElementControlFormWrapperComponent implements OnInit {
  @Input() verificationName: any;
  @Input() defaultVerification: any;
  @Input() radioText: any;
  @Input() radioTextValue: any;
  @Input() id: any;
  @Input() radiovalue: any;
  @Input() radioBtnHeight;
  @Input() controls: FormControl = new FormControl();
  @Input() radioCssValue: any;
  @Input() radioSecondTextCssValue: any;
  @Input() radioLabelValue: any;
  @Input() labelFontValue: any;
  @Input() errorText: string;
  @Input() isErrorText: boolean;
  @Input() isErrorDate: boolean;
  @Input() checkSingleId: boolean;
  @Input() checkedButton: any;
  @Input () isFedexUser: any;
  @Input() dataObj: any;
  @Input() name: any;
  @Input() dataPersonId: any;
  @Output () radiobtnvalue: EventEmitter<string> =  new EventEmitter<string>();
  public isSelected = false;

  ngOnInit() {
    // console.log('checkSingleId', this.checkedButton);
    this.isFlag ();
  }

  emit() {
    sessionStorage.setItem('radiovalue', this.radiovalue);
    this.radiobtnvalue.emit(this.radiovalue);
  }
isFlag () {
    if (this.checkedButton !== undefined && this.dataObj !== undefined) {
      if (this.checkedButton === this.dataObj) {
        this.isSelected = true;
      } else if (this.checkedButton === this.dataPersonId) {
        this.isSelected = true;
      } else {
         this.isSelected = false;
      }
    } else {
      this.isSelected = false;
    }
   }

}
