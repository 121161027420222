import { NgModule,ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {TertiaryPageLabelComponent} from '../sfd/common/atoms/labels/tertiary-page-label/tertiary-page-label.component';
import {PrimaryTextInputComponent} from '../sfd/common/atoms/inputs/primary-text-input/primary-text-input.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { LoginComponent } from '../sfd/login/login.component';
import { PrimaryDropdownComponent } from '../sfd/common/atoms/dropdowns/primary-dropdown/primary-dropdown.component';
import { SecondaryDropdownComponent } from '../sfd/common/atoms/dropdowns/secondary-dropdown/secondary-dropdown.component';
import { PrimaryFormDropdownComponent } from '../sfd/common/molecules/primary-form-dropdown/primary-form-dropdown.component';
import { PrimaryButtonComponent } from '../sfd/common/atoms/button/primary-button/primary-button.component';
import { PrimaryFormTextInputComponent } from '../sfd/common/molecules/primary-form-text-input/primary-form-text-input.component';
import { PrimaryPageLabelComponent } from '../sfd/common/atoms/labels/primary-page-label/primary-page-label.component';
import { SecondaryPageLabelComponent } from '../sfd/common/atoms/labels/secondary-page-label/secondary-page-label.component';
import { SecondaryDateDropdownComponent } from '../sfd/common/molecules/secondary-date-dropdown/secondary-date-dropdown.component';
import { ErrorMessageComponent } from '../sfd/common/atoms/error-message/error-message.component';
import { ToggleButtonComponent } from '../sfd/common/atoms/toggle-button/toggle-button.component';
import { PrimaryRadioComponent } from '../sfd/common/atoms/radio/primary-radio.component';
import { PageSpinnerComponent } from '../sfd/common/molecules/page-spinner/page-spinner.component';
import { ValidateEmailDirective } from './validators/ValidateEmail.directive';
import { ValidateMinLength } from './validators/validateMinLength.directive';
import { ValidatePolicyNumber } from './validators/validatePolicyNumber.directive';
import { ValidateAlphaNumericOnlyDirective } from './validators/alpha-numeric-only.directive';
import { ValidateAlphaOnlyDirective } from './validators/ValidateAlphaOnly.directive';
import { ValidateNumberOnlyDirective } from './validators/number-only.directive';
import { AnchorButtonComponent } from '../sfd/common/atoms/button/anchor-button/anchor-button.component';
import { httpInterceptorProviders } from './intercepter';



const SHARED_COMPONENTS = [
  LoginComponent,
  PrimaryDropdownComponent,
  SecondaryDropdownComponent,
  PrimaryFormDropdownComponent,
  PrimaryButtonComponent,
  AnchorButtonComponent,
  PrimaryFormTextInputComponent,
  TertiaryPageLabelComponent,
  PrimaryTextInputComponent,
  PrimaryPageLabelComponent,
  SecondaryPageLabelComponent,
  SecondaryDateDropdownComponent,
  ErrorMessageComponent,
  ToggleButtonComponent,
  PrimaryRadioComponent,
  PageSpinnerComponent

];

const SHARED_VALIDATORS = [
  ValidateEmailDirective,
  ValidateMinLength,
  ValidatePolicyNumber,
  ValidateAlphaNumericOnlyDirective,
  ValidateAlphaOnlyDirective,
  ValidateNumberOnlyDirective,
];

const SHARED_DIRECTIVES = [
  // NumberOnlyDirective,
  // AlphaNumericOnlyDirective
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    RouterModule
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_VALIDATORS, ...SHARED_DIRECTIVES ],
  exports: [...SHARED_COMPONENTS, ...SHARED_VALIDATORS, ...SHARED_DIRECTIVES ]
})
export class SharedModule { 
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule,
        providers: [httpInterceptorProviders]
        
        
          
}
  }
}
