import { Component, OnInit } from '@angular/core';
import {SharedDataService} from '../../shared-data.service';
import {enumValue} from '../../constant.enum';
@Component({
  selector: 'app-sfd-please-call',
  templateUrl: './sfd-please-call.component.html',
  styleUrls: ['./sfd-please-call.component.scss']
})
/**
 * classs representing a SfdPleaseCallComponent
 */
export class SfdPleaseCallComponent implements OnInit {
  enumValue = enumValue;
  public employeeNo: any;
  public employerNo: any;
  public tokenExpired: boolean;

  /**
   * Create a SfdPleaseCallComponent
   * @param sharedDataService
   */
  constructor( private sharedDataService: SharedDataService) { }

  /**
   * onInit to check expired token
   */
  ngOnInit() {
    this.employeeNo = this.sharedDataService.validatePhone(enumValue.EMPLOYEE_PhoneNo_KEY);
    this.employerNo = this.sharedDataService.validatePhone(enumValue.EMPLOYER_PhoneNo_KEY);
    this.sharedDataService.getExpiredTokenStatus().subscribe(data => {
      this.tokenExpired =  data;
    });
  }
}
