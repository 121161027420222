<div class="secondary-container-label
{{fontWeight}}
 {{labelSize}}-label
  GothamSSm-{{labelWeight}}
  color-{{labelColor}}
"
     >
  {{higLabel}}
{{splitTextFirst}}<br> {{splitTextSecond}}
  <ng-content></ng-content>
</div>
