  /*
* This component is the container of all modals
* Used to hold the content of a modal and display
* it in the styles designed.
*
*/

import {AfterViewInit, Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {SecurityContext} from '@angular/core';
// import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

  @Component({
  selector: 'app-page-spinner',
  templateUrl: 'page-spinner.component.html',
  styleUrls: ['page-spinner.component.scss']
})
export class PageSpinnerComponent implements OnInit, AfterViewInit, OnDestroy {

@Input() active = false;
@Input() activeText = '';


  constructor(
    private domPurify: DomSanitizer
  ) {
    // console.log('in constructor of sliding modal');
  }

  /* Subscribing the modal to the service capabilities
  * */
  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  sanitizeText(text: string): any {
    return this.domPurify.sanitize(SecurityContext.HTML, text);
  }
}
