<!--this section is specific to pass code page and will show warning message as pass code returned -- invalid-->

<div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3 error-main-container" *ngIf="warningBannerFlag">
  <div class="error-container col-xs-12 no-padding">
    <div class="error-icons-margin pull-left">
      <img src = "{{bannerContent.icon}}" alt="Error" />
    </div>
    <div class="incorrect-section pull-left">
      <secondary-page-label higLabel="{{bannerContent.header}}" labelSize="larger" labelWeight="bold" ></secondary-page-label>
    </div>
    <div class="col-xs-12 GothamSSm-Book wrong-code" [innerHTML]="bannerContent.message"></div>
  </div>
</div>

<div  class="card-design col-xs-12  col-sm-10 col-md-6 col-lg-6 col-md-offset-3 col-sm-offset-1 col-lg-offset-3 label-div">
   <ng-content></ng-content>
</div>
