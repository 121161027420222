<!-- Container - holds color and position -->
<footer class="footer footer-thin">
  <div class="container">
    <div class="row content-row">
      <div class="col-md-8 content-center">
        <ul class="links list-inline">
          <li *ngFor="let link of footer_links">
            <a href="{{link.linkVal}}" target="_blank"
               attr.data-dl='{"da_track":"true", "event_id":"{{link.linkName}}", "event":"click"}'>{{link.linkName}}
              <img *ngIf="link.linkImg" src="{{link.linkImg}}" alt="link image" class="footer-img"/>
              </a>
          </li>
        </ul>
        <p class="copyright">&copy; {{copyrightText}}{{' ' + year+' '+ trademarks}}</p>
      </div>
      <div class="col-md-2">
        <img src="https://s0.hfdstatic.com/sites/higux/dist/images/norton.svg" [alt]="enumValue.VERI_SIGN_KEY" class="norton">
      </div>
    </div>
  </div>
</footer>
