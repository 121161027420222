import { NgModule } from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
import { SfdPleaseCallComponent } from './sfd/sfd-please-call/sfd-please-call.component';
import { SfdCustomErrorMessageComponent } from './sfd/sfd-custom-error-message/sfd-custom-error-message.component';
import { SfdDefaultComponent } from './sfd/sfd-default/sfd-default.component';
import { SfdConfirmationComponent } from './sfd/sfd-confirmation/sfd-confirmation.component';
import { AuthGuard } from './shared/services/auth.guard';
export const ROUTES: Routes = [
  {
    path: '',
    component: SfdDefaultComponent
  },
  {
    path:   'signup',
    loadChildren: () => import('./sfd/sfd-signup/sfd-signup.module').then(m => m.SfdSignUpModule)
  },
  {
    path:   'contactUs',
    component: SfdPleaseCallComponent
  },
  {
    path:   'unauthorized',
    component: SfdCustomErrorMessageComponent
  },
  {
    path:   'navigation',
    loadChildren: () => import('./sfd/sfd-user-redirect/sfd-user-redirect.module').then(m => m.SfdUserRedirectModule)
  },
  {
    path:   'privilegeUser',
    loadChildren: () => import('./sfd/sfd-privilege-user/sfd-privilege-user.module').then(m => m.SfdPrivilegeUserModule)
  },
  {
    path:   'confirmationPage',
    component: SfdConfirmationComponent,
    canActivate: [AuthGuard]
  },
  {path: '**', component: SfdDefaultComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {preloadingStrategy: PreloadAllModules}
      /*,{enableTracing: true}*/), // for debugging purposes only
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
