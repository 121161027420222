import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {StorageManagementService} from './storage-management.service';
import {AuthStateService} from './auth-state.service';
import {AuthService} from '../../auth.service';
@Injectable()
export class SessionManagementService {

  private CHECK_INTERVAL = 60000;
  private MINUTES_UNTIL_LOGOUT = 1;
  private TIMEOUT_KEY = 'lastAction';
  private MINUTES_UNTIL_DATA_FETCH = 5;
  private DATA_FETCH_KEY = 'lastDataFetch';
  constructor(
    private authState: AuthStateService,
    private storageManagementService: StorageManagementService,
    private ngZone: NgZone,
    private router: Router,
    private authService: AuthService
  ) {
    this.lastAction = Date.now();
    this.initListener();
    this.initInterval();
  }
  get lastAction() {
    return parseInt( this.storageManagementService.retrieveSessionObject(this.TIMEOUT_KEY), 10 );
  }

  set lastAction( value ) {
    this.storageManagementService.setSessionObjectNoJSON(this.TIMEOUT_KEY, value);
  }

  get isLoggedIn() {
    return this.storageManagementService.retrieveSessionObject('logState');
  }

  set lastDataFetch( value ) {
    this.storageManagementService.setSessionObjectNoJSON(this.DATA_FETCH_KEY, value);
  }

  get lastDataFetch() {
    return parseInt( this.storageManagementService.retrieveSessionObject(this.DATA_FETCH_KEY), 10 );
  }

  set claimsFlag(value) {
    this.storageManagementService.setClaimsFlag(value);
  }

  get claimsFlag() {
    return this.storageManagementService.getClaimsFlag();
  }
  /*
    Called in home.component on every claims data fetch
   */
  initDataFetch() {
    this.lastDataFetch = Date.now();
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  reset() {
    this.lastAction = Date.now();
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.checkTimeOut();
        this.checkUserDataUpdate();
      }, this.CHECK_INTERVAL);
    });
  }

  checkTimeOut() {
    const now = Date.now();
    const timeLeft = this.lastAction + this.MINUTES_UNTIL_LOGOUT * 60 * 1000;
    const difference = timeLeft - now;
    if ( difference < 0 && this.isLoggedIn ) {
      this.ngZone.run( () => {
        // this.authService.logout(true);
        // this.router.navigate(['/login']);
        // this.storageManagementService.setSessionObjectNoJSON('autoLogOut', true);
      });
    }
  }

  checkUserDataUpdate() {
    const now = Date.now();
    const timeLeft = this.lastDataFetch + this.MINUTES_UNTIL_DATA_FETCH * 60 * 1000;
    const difference = timeLeft - now;

    if ( difference < 0 && this.lastDataFetch && !this.claimsFlag) {
      this.claimsFlag = true;
    }
  }
}
