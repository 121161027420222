import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';
import {env} from './environments/environment-loader';
if (env.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);

