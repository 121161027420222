
import {map} from 'rxjs/operators';
import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';

import {
  Observable
, BehaviorSubject} from 'rxjs';
import {
  env
} from '../../../environments/environment-loader';
import {
  sfdManagementServiceRequestModel
} from '../../shared/models/sfd-management-service-request.model';
import {
  sfdVerificationRequestModel
} from '../../shared/models/sfd-verification-request.model';
import {
  enumValue
} from '../../constant.enum';


@Injectable()
export class SfdManagementService {
public navigateUrl: any;
  public toggleMsgRibbon: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public updateRibbonContent: BehaviorSubject<object> = new BehaviorSubject<object>({icon: enumValue.WARNING_ICON, header: enumValue.INCORRECT_CODE, message: enumValue.WRONG_CODE_TEXT});
  constructor(private httpClient: HttpClient) {}
  /**
   * postRequestResponse () to call post request api
   * @param  url {string}
   * @param serviceRequest {object}
   * @param token {string}
   * @returns {Observable<Object>}
   */
  postRequestResponse(url: string, serviceRequest: any, ciamToken ?: any) {
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.set(enumValue.CONTENT_TYPE_KEY, enumValue.APPLICATION_KEY);
    if (ciamToken) {
      headers = headers.set(enumValue.CIAM_SESSION_KEY, ciamToken);
    }
    if (serviceRequest.sessionIdentifier) {
      params = params.set(enumValue.SESSION_IDENTIFIER_KEY, serviceRequest.sessionIdentifier);
    }
    if (serviceRequest.tokenType) {
      params = params.set(enumValue.TOKEN_TYPE_TEXT_KEY, serviceRequest.tokenType);
    }
    if (serviceRequest.accessToken) {
      params = params.set(enumValue.ACCESS_TOKENS_KEY, serviceRequest.accessToken);
    }
    if (serviceRequest.passCode) {
      params = params.set(enumValue.PASS_CODE_TEXT_KEY, serviceRequest.passCode);
    }
    return this.httpClient.post(url, serviceRequest, {
      headers, observe: 'response',
      params
    }).pipe(map((response: HttpResponse<Object>) => {
      return response;
    }));
  }
  /**
   * getRequestResponse() to get responce data from api
   * @param url {string}
   * @param token {string}
   * @param channel {string}
   * @param channeslId {number}
   * @returns {Observable<Object>}
   */
  getRequestResponse(url: string, ciamToken: any, channel ?: any, channeslId ?: any, authToken ?: any, accntClaim ?: string) {
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.set(enumValue.CONTENT_TYPE_KEY, enumValue.APPLICATION_KEY);
    if (ciamToken) {
      headers = headers.set(enumValue.CIAM_SESSION_KEY, ciamToken);
    }
    if (authToken) {
      headers = headers.set('authorization', authToken);
    }
    if (channel) {
      params = params.set(enumValue.RESPONSE_TYPE_KEY, channel);
    }
    if (channeslId) {
      params = params.set(enumValue.CHANNEL_ID_KEYS, channeslId);
    }
    if (accntClaim) {
      params = params.set(enumValue.ACT_REG, accntClaim);
    }
    return this.httpClient.get(url, {
      headers, observe: 'response',
      params
    }).pipe(map((response: HttpResponse<Object>) => {
      return response;
    }));
  }

  /**
   * callRegistrationService(): this method is use to call service for signup
   * @param {sfdManagementServiceRequestModel} serviceRequest
   * @returns {Observable<any | any>}
   */
  callCaseIdentifiersService(serviceRequest: sfdManagementServiceRequestModel, ciamToken) {
    return this.postRequestResponse(env.gbCiamCommonUtil.caseIdentifiersApiURL, serviceRequest, ciamToken);
  }
  /**
   * callVerificationService(): this method is use to call vettings service to get verification option for user
   * @param {sfdVerificationRequestModel} serviceRequest
   * @returns {Observable<any | any>}
   */
  callVerificationService(serviceRequest, siteAccessType, ciamToken) {
    if (siteAccessType === 'ER' || siteAccessType === 'CC' ||  siteAccessType === 'MB' || siteAccessType === 'EU' || siteAccessType === 'EM') {
      this.navigateUrl = env.gbCiamCommonUtil.caseVettingsServiceApiURL;
    } else {
      this.navigateUrl = env.gbCiamCommonUtil.vettingServiceApiURL;
    }
    return this.postRequestResponse(this.navigateUrl, serviceRequest, ciamToken);
  }
  /**
   * callauthorizeService () this method is use to call authorize service to get  user details based on CIAM
   * @param token
   * @param channelId
   * @returns {Observable<Object>}
   */
  callauthorizeService(ciamToken, channelId: any, authTokenValue: any, actReg): Observable<any> {
    let channel = '';
    let authTokenValues = authTokenValue;
    const bearers = 'bearer ';
    const channelid = parseInt(channelId, 10);
    const channeslId = channelId;
    switch (channelid) {
      case 1:
        channel = enumValue.SS0_TOKEN_KEY;
        break;
      case 3:
        channel = enumValue.INTERNAL_TOKEN_KEY;
        authTokenValues = bearers.concat(authTokenValue);
        break;
      case 4:
        channel = enumValue.INTERNAL_TOKEN_KEY;
        authTokenValues = bearers.concat(authTokenValue);
        break;
      default:
        channel = enumValue.TOKENS_KEY;
    }
    return this.getRequestResponse(env.gbCiamCommonUtil.authorizeServiceApiURL, ciamToken, channel, channeslId, authTokenValues, actReg);
  }

  /**
   * getPreFieldName () this method use to call 'gb-sfd-service/sfd/v1/ciam/users' to get last name fro any user
   * And  preField on signup page
   * @param token
   * @returns {Observable<Object>}
   */
  getPreFieldName(ciamToken) {
    return this.getRequestResponse(env.gbCiamCommonUtil.preFieldNametApiURL, ciamToken);
  }

  /**
   * getCiamLogoutAPI () this method is call to check user logout or not successfully
   * @param token {string}
   * @returns {Observable<Object>}
   */
  getCiamLogoutAPI(ciamToken) {
    let headers = new HttpHeaders();
    headers = headers.set(enumValue.CONTENT_TYPE_KEY, enumValue.APPLICATION_KEY);
    const data = {};
    if (ciamToken) {
      headers = headers.set(enumValue.CIAM_SESSION_KEY, ciamToken);
    }
    return this.httpClient.post(env.gbCiamCommonUtil.loggedOutApiURL, data, {
      headers, observe: 'response'
    }).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  /**
   * checkForCaseIdentifierValidationAPI() to check identifier fro user
   * @param token {string}
   * @param sessionIdentifier {string}
   * @param tokenType {string}
   * @param accessToken {string}
   * @returns {Observable<Object>}
   */
  checkForCaseIdentifierValidationAPI(checkProfileId, emailToken, sessionIdentifier, tokenType, ciamToken) {
    let params = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.set(enumValue.CONTENT_TYPE_KEY, enumValue.APPLICATION_KEY);
    if (ciamToken) {
      headers = headers.set(enumValue.CIAM_SESSION_KEY, ciamToken);
    }
    if (emailToken) {
      params = params.set(enumValue.ACCESS_TOKENS_KEY, emailToken);
    }
    if (sessionIdentifier) {
      params = params.set(enumValue.SESSION_IDENTIFIER_KEY, sessionIdentifier);
    }
    if (tokenType) {
      params = params.set(enumValue.TOKEN_TYPE_KEY, tokenType);
    }
    if (checkProfileId) {
      params = params.set(enumValue.PROFILEID_TYPE_KEY, checkProfileId);
    }
    return this.httpClient.get(env.gbCiamCommonUtil.caseIdentifierApiURL, {
      headers, observe: 'response',
      params
    }).pipe(map((response: HttpResponse<Object>) => {
      return response;
    }));
  }
  /**
   * logServiceAPI () this method is use to get logs data for logdin user to check status of responce based on token
   * @returns {Observable<Object>}
   * sessionIdentifier
   * @param logText
   * @param debugLevel
   * @param ciamToken
   */
  logServiceAPI(logText, debugLevel, ciamToken ) {
    let headers = new HttpHeaders();
    headers = headers.set(enumValue.CONTENT_TYPE_KEY, enumValue.APPLICATION_KEY);
    headers = headers.set(enumValue.CONTENT_TYPE_KEY, enumValue.APPLICATION_KEY);
    if (ciamToken) {
      headers = headers.set(enumValue.CIAM_SESSION_KEY, ciamToken);
    }
    const data = {
      userId: null,
      logText: logText,
      loggingLevel: debugLevel
    };
    return this.httpClient.post(env.gbCiamCommonUtil.logServiceApiURL, data, {
      headers, observe: 'response'
    }).pipe(map((response: HttpResponse<Object>) => {
      return response;
    }));
  }

  /**
   * to call pending reg service
   * this will return the token. regType, status, etc.
   * @param ciamToken
   */
  callPendingRegService(ciamToken) {
    let headers = new HttpHeaders();
    headers = headers.set(enumValue.CONTENT_TYPE_KEY, enumValue.APPLICATION_KEY);
    if (ciamToken) {
      headers = headers.set(enumValue.CIAM_SESSION_KEY, ciamToken);
    }
    return this.httpClient.get(env.gbCiamCommonUtil.pendingRegApiURL, {
      headers, observe: 'response'
    }).pipe(map((response: HttpResponse<Object>) => {
      return response;
    }));
  }
}
