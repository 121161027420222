import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'custom-page-label',
  templateUrl: 'custom-page-label.component.html',
  styleUrls: ['custom-page-label.component.scss']
})
export class CustomPageLabelComponent implements OnInit {

  @Input() higLabel: string;
  @Input() faIcon: string;
  @Input() myCondition = false;
  @Input() labelSize = 'regular';
  @Input() labelWeight = 'book';
  @Input() labelColor = 'regular';

  constructor() {

  }

  ngOnInit() {

  }

}
