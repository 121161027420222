import {Injectable} from '@angular/core';
import {TealiumDataService} from './tealium-data.service';
import {Location} from '@angular/common';
import {env} from '../../../../environments/environment-loader';
declare var window: any;

@Injectable()
export class TealiumUtagService {
  script_src = '';
  script_src_sync = '';
  script_src_c2e = '';
  config: any;
  locationurl: Location;


  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(private tealiumDataSvc: TealiumDataService, private location: Location) {
    // window.utag_cfg_ovrd = { noview : true };
    window.utag_data = {};
    this.locationurl = location;
  }

  // Generic script loader with callback
  getScript( src: string, callback: Function ) {
    const d = document;
    const o = { callback: callback || function() {} };
    let s, t;

    if ( typeof src === 'undefined' ) {
      return;
    }

    s = d.createElement('script'); s.language = 'javascript'; s.type = 'text/javascript'; s.async = 1; s.charset = 'utf-8'; s.src = src;
    if ( typeof o.callback === 'function' ) {
      if ( d.addEventListener ) {
        s.addEventListener('load', function() {o.callback(); }, false);
      } else {
        // old IE support
        s.onreadystatechange = function() {if (this.readyState === 'complete' || this.readyState === 'loaded') {this.onreadystatechange = null; o.callback(); }};
      }
    }
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  // Todo: Adjust env logic to use Angular features and/or include more environments
  // Config settings used to build the path to the utag.js file
  setConfig() {
    const host: string = window.location.hostname;
    let environment: string = env.locationurl;
    environment = 'dev';
    this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';

    // Determine environment based on app host name
    if (host.toLowerCase().includes('ltd') || host.toLowerCase().includes('ltt') || host.toLowerCase().includes('ltd3')) {
      environment = 'dev';
      this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
    } else if (host.toLowerCase().includes('lqa' ) || host.toLowerCase().includes('qa' )) {
      environment = 'qa';
      this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
    } else if (host.toLowerCase().includes('gbaccount' )) {
      environment = 'prod';
      this.script_src_c2e = 'https://s0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
    }
    /*
     else if(host.toLowerCase().includes(env.LTI || env.INT)) {
     environment = env.DEV;
     }
     else if(host.toLowerCase().includes(env.PROD_QUOTE)) {
     environment = env.PROD;
     }
     */
    this.config = {
      site_name: 'Single Front Door',
      account: 'thehartford',
      profile : 'single-front-door',
      environment : environment,
      locationurl: this.locationurl.path()  // This is also not being used for tealium , we are using it tealium-data.service.ts
    };
    this.script_src_sync = 'https://tags.tiqcdn.com/utag/' + this.config.account + '/' + this.config.profile + '/' + this.config.environment + '/utag.sync.js';
    this.script_src = 'https://tags.tiqcdn.com/utag/' + this.config.account + '/' + this.config.profile + '/' + this.config.environment + '/utag.js';
  }

  // Data layer is optional set of key/value pairs
  track(tealium_event: string, data?: any) {
    if ( this.script_src === '' ) {
      return;
    }
    if ( window.utag === undefined ) {
      this.getScript( this.script_src_sync, function() {});
      this.getScript( this.script_src, function() {
        setTimeout(window.utag.track( tealium_event, data ), 3000) ;
      });
    } else {
      window.utag.track( tealium_event, data );
    }
  }


  view() {
    setTimeout(() => {
      this.setConfig();
      const data = this.tealiumDataSvc.setDataLayer(this.config);
      Object.keys(data).map((key) => {
        window.utag_data[key] = data[key];
      });
      this.track('view', data);
    }, 5000);
  }
}
