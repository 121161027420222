import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { StorageManagementService } from './shared/services/storage-management.service';
import {env} from '../environments/environment-loader';
import {SharedDataService} from './shared-data.service';
import { enumValue } from './constant.enum';
@Injectable()
export class AuthService  {
  enumValue = enumValue;
  constructor(private route: Router,
              private sharedDataService: SharedDataService,
              private storageManagementService: StorageManagementService,
              ) { }

  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable(); }

  notifyUserAction() {
    this._userActionOccured.next();
  }
  logOutUser() {
    this.logout();
  }

  sendToken(token: string) {
    sessionStorage.setItem('LoggedInUser', token);
  }
  getToken() {
    return sessionStorage.getItem('LoggedInUser');
  }
  setgbUser(token: string) {
    sessionStorage.setItem('gbUser', token);
  }
  getgbUser() {
    return sessionStorage.getItem('gbUser');
  }
  setprivUser(token: string) {
    sessionStorage.setItem('privUser', token);
  }
  getprivUser() {
    return sessionStorage.getItem('privUser');
  }

  setUserId(token: string) {
    sessionStorage.setItem('userId', token);
  }
  getUserId() {
    return sessionStorage.getItem('userId');
  }

  /**
   *called when user logged out
   */
  logout () {
    this.sharedDataService.setIsAutoLogout('true');
    this.route.navigate([enumValue.LOGOUT_PAGE_KEY] );
  }
}
