

  <!--
    Display heading text "Let's Talk Instead."
    -->
  <div class="col-sm-12 ">
    <primary-page-label labelSize="larger" align="left" labelWeight="bold"
                        [higLabel]="enumValue.CONFIRMATION_TEXT_KEY" labelColor="blue"></primary-page-label>
  </div>

  <div class="col-sm-12 mT20">
    <p>{{enumValue.CONFIRMATION_BODY_TEXT}}</p>
    <!-- <tertiary-page-label labelSize="regular" align="left"  higLabel="{{enumValue.CONFIRMATION_BODY_TEXT}}"></tertiary-page-label> -->
  </div>


  <div class="row button-space">
    <div class="col-xs-12 col-sm-6  col-md-3  mB10  button-spacing extra-spacing mL3">
      <anchor-button color="blue"
                     text="Visit The Resource Center "
                     path="{{navToResourceUrl}}"
                     type="external"
                     btnId="resource_center_button"
                     
                     ></anchor-button>
    </div>
    <div class="col-xs-12 col-sm-6 col-sm-offset-3 col-md-3 mB10  button-spacing extra-spacing">
      <primary-button btnId="registerButtonId" (click)="next()"
                      [buttonText]="enumValue.CONFIRMATION_BUTTON_TEXT_KEY_NEXT"
                      attr.data-dl='{"da_track":"true","event_id":"{{enumValue.CONFIRMATION_BUTTON_TEXT_KEY_NEXT}}","event":"click", "event_type":"Button Click"}'


      ></primary-button>
    </div>
  </div>







