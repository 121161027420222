import {Injectable} from '@angular/core';
import { EncryptUtil } from '../utils/EncryptUtil';

@Injectable()
export class CustomCookieService {
  /**
   * to set cookies
   * @param cookieName
   * @param cookieValue
   */

   constructor(private eUtil: EncryptUtil) {}
   setCustomCookie(cookieName: any, cookieValue: any) {
    const d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    console.log('setCustomCookie: customCookieService.ts \nOriginal:', cookieValue, '\nNew:', this.eUtil.encodeComponent(cookieValue) );
    document.cookie = cookieName + '=' + this.eUtil.encodeComponent(cookieValue) + ';' + expires + ';path=/' + ';domain=.thehartford.com;';
  }

  /**
   * to get cookies
   * @param cookieName
   */
   getCustomCookie(cookieName: any) {
    const name = cookieName + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        console.log('getCustomCookie: customCookieService.ts \nOriginal:', c.substring(name.length, c.length), '\nNew:', this.eUtil.decodeComponent(c.substring(name.length, c.length)));
        return this.eUtil.decodeComponent(c.substring(name.length, c.length));
      }
    }
    return null;
  }

  /**
   * to delete cookies
   * @param cookieName
   */
  deleteCustomCookie(cookieName: any) {
    const d = new Date();
    d.setTime(d.getTime() * 0);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cookieName + '=' + '' + ';' + expires + ';path=/' + ';domain=.thehartford.com;';
  }
}
