import {NgModule} from '@angular/core';
import {HttpClientModule,HttpClientJsonpModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';

// Used to share LinkDirective and UI tagging across multiple components
@NgModule({
  imports: [
    RouterModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule
  ],
  declarations: [
  ],
  providers: [


  ],
  exports: [
  ]
})
export class TealiumModule {
}

