import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Injectable()
export class EncryptUtil {
    private window: Window;
    private base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    private onlyNumbers = /^\d+$/;
    constructor(@Inject(DOCUMENT) private document: Document) {
        this.window = this.document.defaultView;
     }
    public encodeComponent(data: string): any {
        return this.window.btoa(data);
    }
    public decodeComponent(encryptedData: any): any {
        console.log(14, encryptedData);
        try {
            if (typeof encryptedData === 'string') {
                console.log(17, encryptedData);
                // check if string is encoded AND does not comprise of only numbers (IE: Will ignore Ids)
                console.log(19, !(this.onlyNumbers.test(encryptedData)) , this.base64regex.test(encryptedData));
                if (!(this.onlyNumbers.test(encryptedData)) && this.base64regex.test(encryptedData)) {
                    console.log(21, encryptedData);
                    console.log(22, this.window.atob(encryptedData));
                    return this.window.atob(encryptedData);
                }
            }
        } catch (error) {}
        // if not of type string or if string is not encoded, return the original string.
        return encryptedData;
    }
    public encodeJson(jsonObject: any): any {
        const newJson = new Map();
        Object.keys(jsonObject).forEach(key => {
            newJson.set(key, this.encodeComponent(jsonObject[key]));
        });
        return newJson;
    }
    public decodeJson(encJsonObject: any): any {
        const newJson = new Map();
        Object.keys(encJsonObject).forEach(key => {
            console.log(41, key, encJsonObject[key]);
            newJson.set(key, this.decodeComponent(encJsonObject[key]));
        });
        return newJson;
    }
    public encodeCookie(cookies: string): any {
      return cookies.split('; ').map((cookie) => {
          const splitCookie = cookie.split('=');
          return `${splitCookie[0]}=${this.encodeComponent(splitCookie[1])};`;
      }).join(' ');
    }
}
