import { Component, OnInit } from '@angular/core';
import {SharedDataService} from '../../shared-data.service';
import {enumValue} from '../../constant.enum';
@Component({
  selector: 'app-sfd-custom-error-message',
  templateUrl: './sfd-custom-error-message.component.html',
  styleUrls: ['./sfd-custom-error-message.component.scss']
})
/**
 * classs representing a SfdCustomErrorMessage
 */
// tslint:disable-next-line:component-class-suffix
export class SfdCustomErrorMessageComponent implements OnInit {
  enumValue = enumValue;
  public errorCode: any;
  public ssoOnlyError: boolean;
  public caseExclusionError: boolean;
  public SSO_ONLY_HEADING_TEXT_KEY: any;
  public SSO_ONLY_SUB_HEADING_TEXT_KEY: any;


  /**
   * Create a SfdCustomErrorMessage
   * @param sharedDataService
   */
  constructor( private sharedDataService: SharedDataService) { }

  /**
   * onInit to check text
   */
  ngOnInit() {
    this.sharedDataService.getErrorCode().subscribe(data => {
      this.errorCode =  data;
    });

  }
}
