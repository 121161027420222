<!--
  Display KeyLock icon
  -->

 
  <div class="col-sm-12 error-page text-center missing-page" *ngIf="errorCode === enumValue.SSO_ONLY_ERROR_KEY">
    <a><img src="../../../assets/images/icons/employer-sso-only-icon.svg" width="200" height="190" [alt]="enumValue.SSO_ONLY_ICON_KEY"/></a>
  </div>
  <div class="col-sm-12 error-page text-center missing-page" *ngIf="errorCode === enumValue.CASE_EXCLUSION_KEY">
    <a><img src="../../../assets/images/icons/case-exclusion-icon.svg" width="200" height="190" [alt]="enumValue.CASE_EXCLUSION_ICON_KEY"/></a>
  </div>
  <!--
    Display heading text"
    -->
  <div class="col-sm-12 text-center missing-top" *ngIf="errorCode=== enumValue.SSO_ONLY_ERROR_KEY">
    <primary-page-label labelSize="larger" align="center" labelWeight="bold"
                       [higLabel]="enumValue.SSO_ONLY_HEADING_TEXT_KEY" labelColor="blue"></primary-page-label>
  
  </div>
  <div class="col-sm-12 text-center missing-top" *ngIf="errorCode=== enumValue.SSO_ONLY_ERROR_KEY">
    <primary-page-label align="center" 
                        [higLabel]="enumValue.SSO_ONLY_SUB_HEADING_TEXT_KEY" labelColor="blue"></primary-page-label>
  </div>
  <div class="col-sm-12 text-center missing-top" *ngIf="errorCode=== enumValue.CASE_EXCLUSION_KEY">
    <primary-page-label labelSize="larger" align="center" labelWeight="bold"
                       [higLabel]="enumValue.CASE_EXCLUSION_HEADING_TEXT_KEY" labelColor="blue"></primary-page-label>
  
  </div>
  <div class="col-sm-12 text-center missing-top" *ngIf="errorCode=== enumValue.CASE_EXCLUSION_KEY">
    <primary-page-label align="center" 
                        [higLabel]="enumValue.CASE_EXCLUSION_SUB_HEADING_TEXT_KEY" labelColor="blue"></primary-page-label>
  </div>
  