import {Component, Input, OnInit} from '@angular/core';
import {CustomPageLabelComponent} from '../../../sub-atomic/custom-page-label/custom-page-label.component';
import { DomSanitizer } from '@angular/platform-browser';
import {SecurityContext} from '@angular/core';

@Component({
  selector: 'tertiary-page-label',
  templateUrl: 'tertiary-page-label.component.html',
  styleUrls: ['tertiary-page-label.component.scss']
})
export class TertiaryPageLabelComponent  extends CustomPageLabelComponent implements OnInit {


  @Input() higLabel: string;
  @Input() labelDisabled = false;
  @Input() errorCondition: boolean;
  @Input() focusCondition: boolean;
  @Input() faIcon: string;
  @Input() innerHtmlval: string;
  @Input() isErrorText: boolean;
  @Input() errorText: string;
  @Input() checkSingle: boolean;
  @Input() radioButtonText: string;
  @Input() radioClass: any;
  @Input() radioclassText: any;
  @Input() labelSize: any;
  @Input () textId: any;
  public secondText: any;
  public radioClassCss: any;
  public radioSecondTextCss: any;
  public labelSizeValue: any;
  public radiobuttonchng: any;
  constructor(
    private domPurify: DomSanitizer
  ) {
    super();
  }
  ngOnInit() {
    if (this.radioButtonText) {
      this.secondText = this.radioButtonText;
      this.radioClassCss =  this.radioClass;
      this.radioSecondTextCss = this.radioclassText;
      this.labelSizeValue = this.labelSize;
      // this.ra  diobuttonchng = "<b>this.higLabel</b>"+ this.secondText ;

    }
  }
  sanitizeText(text: string): any {
    console.log('tertiary-page-label.ts \nOriginal:', text, '\nNew:', this.domPurify.sanitize(SecurityContext.HTML, text));

    return this.domPurify.sanitize(SecurityContext.HTML, text);
  }

}
