import { Component, OnInit } from '@angular/core';
import {SharedDataService} from '../../shared-data.service';
import {enumValue} from '../../constant.enum';
import { env } from '../../../environments/environment-loader';
@Component({
  selector: 'app-sfd-confirmation',
  templateUrl: './sfd-confirmation.component.html',
  styleUrls: ['./sfd-confirmation.component.scss']
})
/**
 * classs representing a SfdConfirmationComponent
 */
export class SfdConfirmationComponent implements OnInit {
  enumValue = enumValue;
  public navToResourceUrl = enumValue.CONFIRMATION_PAGE_URL_KEY;
  public sfdUrl = env.gbCiamCommonUtil.SfdAccountUrl;

  /**
   * Create a SfdConfirmationComponent
   * @param sharedDataService
   */
  constructor( private sharedDataService: SharedDataService) { }

  /**
   * onInit to check expired token
   */
  ngOnInit() {

  }
  next() {
    this.sharedDataService.setSessionData(enumValue.REGISTER_USER, enumValue.REGISTERED);
    window.location.href = this.sfdUrl;
  }

}
