import {Component, EventEmitter, OnChanges, OnInit, Output} from '@angular/core';
import {ErrorMessagingService} from '../../../../shared/services/error-messaging.service';


@Component({
  selector: 'element-control-form-wrapper',
  templateUrl: 'element-control-form-wrapper.component.html'
})
export class ElementControlFormWrapperComponent implements OnInit, OnChanges {

  @Output() blur = new EventEmitter<boolean>();
  @Output() focus = new EventEmitter<boolean>();
  @Output() change = new EventEmitter<boolean>();
  private errors;
  public focused;
  public blurred;

  constructor(private errorMessagingService: ErrorMessagingService) {}

  ngOnInit() {
    this.focused = false;
    this.blurred = false;
  }
  ngOnChanges() {
   // console.log("In NG on Changes");
   // console.log(this.controls.value);
  }

  onError(errorList: object[]) {
    this.errors = errorList;
  }

  onFocus(focused: boolean) {
    this.focused = true;
    this.blurred = false;
    this.focus.emit(true);
  }

  onBlur(blurred: boolean) {
    this.focused = false;
    this.blurred = true;
    this.blur.emit(true);
  }

  onChange(changed: boolean) {
    this.change.emit(true);
  }

  errorForDisplay(errorList) {
    for (const key in errorList) {
      // console.log("Key= " + key);
      if ( errorList.hasOwnProperty(key) ) {
        return this.errorMessagingService.getError(key.toString());
      }
    }
  }


}
