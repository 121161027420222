<button
  class="btn btn-primary {{buttonSize}} color-{{buttonColor}} {{buttonLoading}} {{textWeight}} default-width bold-text"
  [id]="btnId"
  role="button"
  [attr.aria-label]="buttonText"
  [disabled]="buttonDisabled"
  (click)="buttonClicked($event)">
  {{buttonText}}
</button>

