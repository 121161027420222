import {Injectable} from '@angular/core';



@Injectable()
export class ErrorMessagingService {
  myErrors = {
    validateSequentialDigits: 'Please enter a valid phone number.',
    validateConsecutiveDigits: 'Please enter a valid phone number.',
    ValidateTwoDigitsRequired: 'Please enter a two-digit number.',
    required: ' is required.',
    RequiredEmail : 'We need your email address to continue.',
    RequiredMobile : 'We need your mobile number to continue.',
    validateEmail: 'Please enter an email in the format xxx@xxx.xxx.',
    getEmailValidator: 'It looks like that\'s not a valid email address.',
    getAlphaValidator: 'Hmm, that\'s not quite right. Please enter alpha characters only.',
    mobileNumberValidator: 'Looks like that might not be a real number. Please double check and try again.',
    pattern: 'Please enter a valid pattern.',
    AccountTypeValidator: 'Please Select Account Type.',
    minLength: 'Please Provide minimum length.',
    maxLength: 'Length is too much.',
    minlength: '  is required.',
    accMinlength: ' Your account number should be in-between 5 and 17 digits.',
    maxlength: 'Length is too much.',
    incorrectRoutingNo: 'Routing Number is incorrect.',
    invalidZip: 'Please double check your zip code and re-enter.',
    validateMinLength: 'Zip code must be at least 5 characters.',
    incorrectInsuredId: 'It looks like that didn’t work. Please double-check your information and try again.',
    passwordMismatch: 'These don’t match. Please try again.',
    newAndOldPasswordSame: 'The new and old password cannot be same.',
    validatePolicyNumber: 'Last 5 digits of Social Security Number must be at least 5 characters.',
    validateSNNNumber: 'Social Security Number must be at least 9 characters.',
    NameValidator: 'Please enter a valid pattern.',
    policyNumberNotFound: `We can't find that policy number, please try again.`,
    // validateZipMinLength: 'Zip code must be at least 5 characters.'
  };
  constructor() {

  }

  /**
   * to get error from above list
   * @param errKey
   */
  getError(errKey) {
    return this.myErrors[errKey];
  }
}
