
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';


@Injectable()
export class AppIDRoutingService {
  constructor(private router: Router) {}
  routerNavigate(url: string) {
    if (url.includes('goto')) {
      this.router.navigate([url], {queryParams: {appid: sessionStorage.appID}, queryParamsHandling: 'merge'} );
    } else {
      this.router.navigate([url], {queryParams: {appid: sessionStorage.appID}} );
    }
  }
}
