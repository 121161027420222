
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import { EncryptUtil } from '../utils/EncryptUtil';
@Injectable()
export class AuthStateService {
  constructor(
              private router: Router,
              public httpClient: HttpClient,
              public eUtil: EncryptUtil
) {}
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): any {
    sessionStorage.setItem('usertype', route.queryParams['usertype']);
      /**
       * Proceeding forward
       */
        return observableOf(true);
    }
  }

