<a href="javascript:void(0)" (click)="toggle()" id="bt" class="toggle-button" [attr.arial-label]="buttonText">
  <span *ngIf="buttonName === 'Show'" class="fa fa-plus"></span>
  <span *ngIf="buttonName === 'Hide'" class="fa fa-minus"></span>
  {{buttonText}}
</a>
<ng-container *ngIf="show">
  <div class="toggle-container">
    <div class="toggle-body">
      {{bodyText}}
    </div>
  </div>
</ng-container>
