// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// API URLs



import {GbNavigation} from './urlFactory/gb-navigation';
import {GbCiamCommonUtil} from './urlFactory/gb-ciam-common-util';

export class Environment {
  constructor(private clientId: Map<string, string>,
              private baseUrl: string,
              private envName: string,
              private mockingSet: boolean,
              private prodState: boolean,
              private proxyState: boolean,
              private ciamAccountUrl: string,
              private sfdAccountUrl: string,
              private ciamApiSgwUrl: string,
              private agentLessToggle: boolean
              ) {
  }

  getEnv() {
    return {
      // Common Application parameters
      production: this.prodState,
      mocking: this.mockingSet,
      envName: this.envName,
      myBaseUrl: this.baseUrl,
      myClientId: this.clientId,
      proxyState: this.proxyState,
      ciamAccountUrl: this.ciamAccountUrl,
      sfdAccountUrl: this.sfdAccountUrl,
      ciamApiSgwUrl: this.ciamApiSgwUrl,
      gbCiamCommonUtil: new GbCiamCommonUtil(this.clientId, this.baseUrl, this.envName, this.ciamAccountUrl, this.sfdAccountUrl, this.ciamApiSgwUrl).getUrls(),
      gbNavigation: new GbNavigation(this.clientId, this.baseUrl, this.envName).getUrl(),
      agentLessToggle: this.agentLessToggle
    };
  }
}
