import {GbBase} from './gb-base';

export class GbCiamBase extends GbBase {

  private ciamAccountUrl: string;
  private envNameForCIAM: string;
  private ciamAppId: string;
  private sfdAccountUrl: string;
  private ciamApiSgwUrl: string;
  constructor(clientId: Map<string, string>, baseUrl: string, envName: string, ciamAccountUrl: string, sfdAccountUrl: string, ciamApiSgwUrl: string) {
    super(clientId, baseUrl, envName);
    this.ciamAccountUrl = ciamAccountUrl;
    this.envNameForCIAM = envName;
    this.sfdAccountUrl = sfdAccountUrl;
    this.ciamApiSgwUrl = ciamApiSgwUrl;
  }

  /*getClientId(id) {
    return 'client_id=' + this.getClient(id) + (this.getEnvName() === 'qa' ? '&target_env=qa3' : '');
  }*/

  // getClientId(id) {
  //   let targetEnv;
  //   switch (this.getEnvName()) {
  //     case 'qa':
  //       targetEnv = '&target_env=qa3';
  //       break;
  //     case 'lqa':
  //       targetEnv = '&target_env=qa';
  //       break;
  //     case 'ltd2':
  //       targetEnv = '&target_env=LTD2';
  //       break;
  //     case 'ltd3':
  //       targetEnv = '&target_env=LTD3';
  //       break;
  //     default:
  //       targetEnv = '';
  //       break;
  //   }
  //   return 'client_id=' + this.getClient(id) + targetEnv;
  // }
  getCiamAccountUrl() {
    return this.ciamAccountUrl;
  }
  getSfdAccountUrl() {

    return this.sfdAccountUrl;
  }
  getEnvName() {
    return this.envNameForCIAM;
  }
  getCiamApiSgwUrl(){
    return this.ciamApiSgwUrl;
  }

  getCiamAppId() {
    if (this.getEnvName() === 'local') {
      return this.ciamAppId = 'EE';
    } else if (this.getEnvName() === 'LTD') {
      return this.ciamAppId = 'EELTD';
    } else if (this.getEnvName() === 'LTD2') {
      return this.ciamAppId = 'EELTD2';
    } else if (this.getEnvName() === 'LTD3') {
      return this.ciamAppId = 'EELTD3';
    } else if (this.getEnvName() === 'lqa') {
      return this.ciamAppId = 'EELQA';
    } else if (this.getEnvName() === 'lqa2') {
      return this.ciamAppId = 'EELQA2';
    } else if (this.getEnvName() === 'lqa3') {
      return this.ciamAppId = 'EELQA3';
    } else if (this.getEnvName() === 'qa3') {
      return this.ciamAppId = 'EESQA3';
    } else if (this.getEnvName() === 'pte') {
      return this.ciamAppId = 'EE';
    } else if (this.getEnvName() === 'qa') {
      return this.ciamAppId = 'EE';
    } else if (this.getEnvName() === 'qa4') {
      return this.ciamAppId = 'EELQA2';
    } else {
      return this.ciamAppId = 'EE';
    }
  }
}
