<!--
  Display phone icon
  -->
<div class="col-sm-12 error-page text-center missing-page">
  <a><img src="../../../assets/images/icons/Phones.png" width="75" height="72" [alt]="enumValue.PHONE_ICON_KEY"/></a>
</div>
<!--
  Display heading text "Let's Talk Instead."
  -->
<div class="col-sm-12 text-center missing-top">
  <primary-page-label labelSize="larger" align="center" labelWeight="bold" role="heading" aria-level="1"
                      [higLabel]="enumValue.TALK_TEXT_KEY" labelColor="blue"></primary-page-label>
</div>

<div class="col-sm-12 text-center missing-top p25" *ngIf="tokenExpired">
  <tertiary-page-label labelSize="regular" align="center"  higLabel="{{enumValue.EXPIRED_INFO_TEXT}}"></tertiary-page-label>
</div>
<!--
  Display heading text "Having trouble logging in?"
  -->
<div class="col-sm-12  text-left mB1">
  <tertiary-page-label labelSize="medium" align="left" labelWeight="medium"
                       [higLabel]= "enumValue.LOGGING_TEXT_KEY"></tertiary-page-label>
</div>
<!--
  Display phone no. for employee on desktop view
  -->
<div class="col-sm-12 visible-sm-block visible-md-block visible-lg-block  text-left">
<tertiary-page-label labelSize="smaller" align="left" labelWeight="bold"
                     [higLabel]= "enumValue.PHONE_TEXT_KEY" [radioButtonText]=employeeNo></tertiary-page-label>
  </div>
<!--
  Display phone no. for employee on mobile view
  -->

<div class="col-sm-12 visible-xs  text-left dI12 mB1">
 <div class="pull-left">
   <tertiary-page-label labelSize="smaller" align="left" labelWeight="bold"
                        [higLabel]= "enumValue.PHONE_TEXT_KEY" ></tertiary-page-label>
 </div>
  <a id="contactHIGId" href="tel:{{employeeNo}}"
               ><u>{{employeeNo}}</u></a>
  </div>
<!--
  Display Availability time "Monday - Friday 9AM - 9PM EST"
  -->
<div class="col-sm-12  text-left  secondaryLabel">
  <tertiary-page-label labelSize="smaller" align="left" labelWeight="bold" [higLabel] = "enumValue.AVAILABLE_TEXT_KEY"
                       [radioButtonText] ="enumValue.TIMING_TEXT_KEY"></tertiary-page-label>
</div>
<!--
  Display heading text "Questions about your claims?"
  -->
<div class="col-sm-12 text-left mB1">
  <tertiary-page-label labelSize="medium" align="left" labelWeight="medium"
                       [higLabel]= "enumValue.CLAIMS_TEXT_KEY"></tertiary-page-label>
 </div>
<!--
  Display phone no. for employee on desktop view
  -->
<div class="col-sm-12 visible-sm-block visible-md-block visible-lg-block  text-left">
  <tertiary-page-label labelSize="smaller" align="left" labelWeight="bold"
                       [higLabel]= "enumValue.PHONE_TEXT_KEY" [radioButtonText]=employerNo></tertiary-page-label>
</div>
<!--
  Display phone no. for employee on mobile view
  -->

<div class="col-sm-12 visible-xs  text-left dI12 mB1">
  <div class="pull-left">
    <tertiary-page-label labelSize="smaller" align="left" labelWeight="bold"
                         [higLabel]= "enumValue.PHONE_TEXT_KEY" ></tertiary-page-label>
  </div>
  <a id="contactsHIGId" href="tel:{{employerNo}}"
  ><u>{{employerNo}}</u></a>
</div>
<!--
  Display Availability time "Monday - Friday 9AM - 9PM EST"
  -->
<div class="col-sm-12  text-left  secondaryLabel">
  <tertiary-page-label labelSize="smaller" align="left" labelWeight="bold" [higLabel] = "enumValue.AVAILABLE_TEXT_KEY"
                       [radioButtonText] ="enumValue.TIMING_TEXT_KEY"></tertiary-page-label>
</div>
