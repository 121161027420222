
<a *ngIf="type == 'download'"
   class="btn btn-primary  color-{{color}}"
   href="{{path}}" [attr.aria-label]="text"
   [id]="btnId ? btnId : 'anchor-button'"
   [attr.target]="isIE ? 'blank' : null"
   download>
  {{text}}
</a>
{{download}}
<a *ngIf="type == 'newTab'"
   class="btn btn-primary  color-{{color}}"
   href="{{path}}"
   [attr.aria-label]="text"
   [id]="btnId ? btnId : 'anchor-button'"
   target="blank">
  {{text}}
</a>
<a *ngIf="type == 'external'"
   class="btn btn-default  color-{{color}}"
   href="{{path}}"
   target="_blank"
   [attr.aria-label]="text"
   [id]="btnId ? btnId : 'anchor-button'">
  {{text}}
</a>
<a  *ngIf="type == 'url'"
    class="btn btn-primary  color-{{color}}"
    routerLink="{{path}}" [attr.aria-label]="text"
    [id]="btnId ? btnId : 'anchor-button'">
  {{text}}
</a>

