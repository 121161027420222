import {Injectable} from '@angular/core';


export enum SessionKey {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  CURRENT_URL,
  PREVIOUS_URL
}

@Injectable()
export class SessionService {


  clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  /**
   * used for setting data in local storage
   * @param key
   * @param value
   */

  setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * to get local storage
   * @param key
   */
  getLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  /**
   * to set item in session storage
   * @param key
   * @param value
   */
  setSession(key: SessionKey, value: any): void {
    sessionStorage.setItem(SessionKey[key], value);
  }

  /**
   * to get item from session storage
   * @param key
   */
  getSession(key: SessionKey) {
    return sessionStorage.getItem(SessionKey[key]);
  }

  /**
   * to remove item from session storage
   * @param key
   */
  removeSession(key: SessionKey) {
    sessionStorage.removeItem(SessionKey[key]);
  }

  /**
   * to set session with Json object
   * @param key
   * @param value
   */
  setSessionJson(key: SessionKey, value: any): void {
    this.setSession(key, JSON.stringify(value));
  }

  /**
   * to get session json object
   * @param key
   */
  getSessionJson(key: SessionKey) {
    /*if(!this.isNullOrUndefinedOrEmpty(this.getSession(key))) {
      return JSON.parse(this.getSession(key));
    }*/
  }

  /**
   * @deprecated Use setSession(key:SessionKey,value:any) instead so that we can more easily track session variables.
   */
  setSessionString(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }

  /**
   * @deprecated Use getSession(key:SessionKey) instead
   */
  getSessionString(key: string) {
    return sessionStorage.getItem(key);
  }
  /**
   * @deprecated Use removeSession(key:SessionKey) instead
   */
  removeSessionString(key: string) {
    return sessionStorage.removeItem(key);
  }

  /**
   * @deprecated Use setSessionJson(key:SessionKey,value:any) instead so that we can more easily track session variables.
   */
  setSessionJsonString(key: string, value) {
    this.setSessionString(key, JSON.stringify(value));
  }

  /**
   * @deprecated Use getSessionJson(key:SessionKey) instead
   */
  getSessionJsonString(key: string) {
    return JSON.parse(this.getSessionString(key));
  }
/*  isNullOrUndefinedOrEmpty(value: any) {
    if (isNullOrUndefined(value) || value === "" || value==="undefined") {
      return true
    }
    return false
  }*/
}
