import {Component} from '@angular/core';
import {CustomPageLabelComponent} from '../../../sub-atomic/custom-page-label/custom-page-label.component';

@Component({
  selector: 'primary-page-label',
  templateUrl: 'primary-page-label.component.html',
  styleUrls: ['primary-page-label.component.scss']
})
export class PrimaryPageLabelComponent extends CustomPageLabelComponent  {

}
