

  <div  class="container">
    <div class="row">
      <div class="navbar-header">
        <button aria-expanded="false" class="navbar-toggle collapsed hidden-xs hidden-sm" data-target="#navBar" data-toggle="collapse" type="button">
          <span  class="sr-only">Toggle navigation</span>
          <em class="icon icon-menu"></em>
        </button>

        <a  class="navbar-brand" aria-label="The Hartford" href="https://www.thehartford.com/">
          <img src="{{hartFordLogo}}" class="img-responsive" [alt]="enumValue.HIG_LOGO_KEY"/></a>
      </div>
    </div>
  </div>

  <header  class="navbar navbar-default collapse navbar-collapse" id="navBar" role="banner">
    <div  class="navbar-primary" id="primaryNav">
      <div  class="container">
        <div  class="row">
          <ul  class="nav navbar-nav navbar-left">
            <li> <button aria-expanded="false" class="navbar-toggle-close collapsed" data-target="#navBar" data-toggle="collapse" type="button">
              <span  class="sr-only">Toggle navigation</span>
              <em  class="icon icon-close"></em>
            </button>
            </li>
            <li>
              <a  aria-expanded="false" aria-haspopup="true" aria-label="dropdown" class="dropdown-toggle invisible" data-toggle="dropdown" href="#" role="button" tabindex="-1">Toggle</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-secondary" id="secondaryNav">
      <div class="container">
        <div  class="row">
          <ul  class="nav navbar-nav">
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
