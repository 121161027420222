import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import { SharedModule } from './shared/shared.module';
import {ElementControlComponent} from './sfd/common/sub-atomic/element-control/element-control.component';
import {ElementControlFormWrapperComponent} from './sfd/common/sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';
import {CustomPageLabelComponent} from './sfd/common/sub-atomic/custom-page-label/custom-page-label.component';
import {CardComponent} from './sfd/common/organisms/card/card.component';
import {ErrorMessagingService} from './shared/services/error-messaging.service';
import {FooterContentService} from './shared/services/footer.service';
import {TextMaskModule} from 'angular2-text-mask';
import {AuthStateService} from './shared/services/auth-state.service';
// import {ValidateEmailDirective} from './shared/validators/ValidateEmail.directive';
// import {ValidateMinLength} from './shared/validators/validateMinLength.directive';
// import {ValidatePolicyNumber} from './shared/validators/validatePolicyNumber.directive';
// import {ValidateAlphaNumericOnlyDirective} from './shared/validators/alpha-numeric-only.directive';
// import {ValidateNumberOnlyDirective} from './shared/validators/number-only.directive';
// import {ValidateAlphaOnlyDirective} from './shared/validators/ValidateAlphaOnly.directive';
import {LinkDirective} from './shared/directives/directives';
import {TealiumDataService} from './shared/services/tealium/tealium-data.service';
import {TealiumUtagService} from './shared/services/tealium/utag.service';
import {TealiumModule} from './shared/modules/tealium.module';
import {SessionService} from './shared/services/tealium/session.service';
import {ElementPropertyDirective} from './shared/directives/element-property.directive';


/** import component file*/

import {SfdManagementService} from './shared/services/sfd-management.service';
import {SfdPleaseCallComponent} from './sfd/sfd-please-call/sfd-please-call.component';
import {HeaderComponent} from './sfd/common/molecules/header/header.component';
import {FooterComponent} from './sfd/common/molecules/footer/footer.component';
import {AuthService} from './auth.service';
import {SharedDataService} from './shared-data.service';
import {CustomCookieService} from './shared/services/customCookieService';
import {SessionManagementService} from './shared/services/session-management.service';
import {StorageManagementService} from './shared/services/storage-management.service';
import {InactivityTimerComponent} from './inactivity-timer.component';
import {AppIDRoutingService} from './routing.service';
import { SfdDefaultComponent } from './sfd/sfd-default/sfd-default.component';
import { ButtonComponent } from './sfd/common/sub-atomic/button/button.component';
import { AppInfoComponent } from './sfd/app-info/app-info.component';
import {MessageRibbonService} from './shared/services/message-ribbon.service';
import { CustomEventService } from './shared/services/tealium/custom-event.service';
import { SfdConfirmationComponent } from './sfd/sfd-confirmation/sfd-confirmation.component';
import { EncryptUtil } from './shared/utils/EncryptUtil';
import {SfdCustomErrorMessageComponent} from './sfd/sfd-custom-error-message/sfd-custom-error-message.component';
import { httpInterceptorProviders } from './shared/intercepter';
import { AuthGuard } from './shared/services/auth.guard';
@NgModule({
  declarations: [
    AppComponent,
    ElementControlComponent,
    ElementControlFormWrapperComponent,
    CustomPageLabelComponent,
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    CardComponent,
    ElementPropertyDirective,
    LinkDirective,
    SfdPleaseCallComponent,
    InactivityTimerComponent,
    SfdDefaultComponent,
    AppInfoComponent,
    SfdConfirmationComponent,
    SfdCustomErrorMessageComponent
  ],
  imports: [
    BrowserModule,
    TealiumModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    TextMaskModule,
    SharedModule

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
  providers: [
    SharedDataService,
    AuthService,
    SfdManagementService,
    ErrorMessagingService,
    FooterContentService,
    AuthStateService,
    TealiumUtagService,
    TealiumDataService,
    SessionService,
    SessionManagementService,
    CustomCookieService,
    StorageManagementService,
    MessageRibbonService,
    CustomEventService,
    AppIDRoutingService,
    EncryptUtil,
    AuthGuard,
    httpInterceptorProviders,
    { provide: 'Window', useValue: window }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
constructor(private router: Router, private tealium: TealiumUtagService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.tealium.view();
      }
    });
  }

}
