
import { enumValue } from '../../app/constant.enum';
import {GbCiamBase} from './gb-ciam-base';

export class GbCiamCommonUtil extends GbCiamBase {
private  randomNo = Math.random();
  getUrls() {
    return {
      ciamLoginURL: this.getCiamAccountUrl() + '/customer/login?goto=' + this.getSfdAccountUrl(),
      ciamTimeoutURL: this.getCiamAccountUrl() + '/customer/login?' + enumValue.SESSION_TIMEOUT_KEY+ '&goto=' + this.getSfdAccountUrl(),
      SfdAccountUrl : this.getSfdAccountUrl(),

      loggedOutApiURL: this.getCiamApiSgwUrl() + 'ea/ciam/auth/sessions?_action=logout&realm=Customers&' + this.getLogOutClientId('auth'),

      pendingRegApiURL: this.getBaseUrl() + 'gb-sfd-service/sfd/v1/sfd/pending-regs?' +  this.getClientId('auth'),
      caseIdentifierApiURL: this.getBaseUrl() + 'gb-sfd-service/sfd/v1/caseIdentifier?' +  this.getClientId('auth'),
      logServiceApiURL: this.getBaseUrl() + 'uws/gb-log-service/log/v1/Log?' + this.getClientId('auth'),
      preFieldNametApiURL: this.getBaseUrl() + 'gb-sfd-service/sfd/v1/ciam/users?' + this.getClientId('auth'),
      caseIdentifiersApiURL: this.getBaseUrl() + 'gb-sfd-service/sfd/v1/caseIdentifiers?' + this.getClientId('auth'),
      vettingServiceApiURL: this.getBaseUrl() + 'gb-sfd-service/sfd/v1/vettings?' + this.getClientId('auth'),
      caseVettingsServiceApiURL: this.getBaseUrl() + 'gb-sfd-service/sfd/v1/caseVettings?' + this.getClientId('auth'),
      registerThaaIdentityServiceApiURL: this.getBaseUrl() + 'gb/net/register-thaa-identity?' + this.getClientId('auth'),
      authorizeServiceApiURL: this.getBaseUrl() + 'authorize?' + this.getClientId('auth') + '&' + this.randomNo
    };
  }
}
