import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() errorFlag: any;
  @Input() errors: string;
  @Input() login: string;
  @Input() errorsText1: string;
  @Input() errorsText2: string;
  @Input() errorsText3: string;
  @Input() errorMsg: string;
  @Input() hrefValue: string;

  @Input() email: string;
  public hg = 'https://www.google.com';
  constructor() { }

  ngOnInit() {
  }

}
