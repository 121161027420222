export class GbBase {
  constructor(private clientId: Map<string, string>, private baseUrl: string, private envName: string) {}

  getClient(id) {
    return this.clientId.get(id);
  }

  getClientId(id) {
    let targetEnv;
    switch (this.envName) {
      case 'QA':
        targetEnv = '&target_env=qa2';
        break;
      case 'QA3':
        targetEnv = '&target_env=sqa3';
        break;
      case 'LTD2':
        targetEnv = '&target_env=LTD2';
        break;
      case 'LTD3':
        targetEnv = '&target_env=LTD3';
        break;
      case 'lqa':
        targetEnv = '&target_env=qa';
        break;
      case 'lqa2':
        targetEnv = '&target_env=qa4';
        break;
      case 'lqa3':
        targetEnv = '&target_env=lqa3';
        break;
      case 'pte':
        targetEnv = '&target_env=pte';
        break;
      case 'LTD':
        targetEnv = '&target_env=int';
        break;
      case 'local':
        targetEnv = '&target_env=LTD2';
        break;
      default:
        targetEnv = '';
        break;
    }
    return 'client_id=' + this.getClient(id) + targetEnv;
  }

  getLogOutClientId(id) {
    let targetEnv;
    switch (this.envName) {
      case 'QA':
        targetEnv = '&target_env=qa';
        break;
      case 'QA3':
        targetEnv = '&target_env=qa';
        break;
      case 'LTD2':
        targetEnv = '&target_env=dev';
        break;
      case 'LTD3':
        targetEnv = '&target_env=dev';
        break;
      case 'lqa':
        targetEnv = '&target_env=qa';
        break;
      case 'lqa2':
        targetEnv = '&target_env=qa';
        break;
      case 'lqa3':
        targetEnv = '&target_env=qa';
        break;
      case 'pte':
        targetEnv = '&target_env=pte';
        break;
      case 'LTD':
        targetEnv = '&target_env=dev';
        break;
      case 'local':
        targetEnv = '&target_env=dev';
        break;
      default:
        targetEnv = '';
        break;
    }
    return 'client_id=' + this.getClient(id) + targetEnv;
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  getEnvName() {
    return this.envName;
  }

}
