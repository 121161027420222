import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {
  SharedDataService
} from '../../shared-data.service';
import {
  enumValue
} from '../../constant.enum';
@Directive({
  selector: '[validatePolicyNumber]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidatePolicyNumber, multi: true}]
})
export class ValidatePolicyNumber implements Validator {

  private pattern = new RegExp('^[0-9]{5,10}$');
  public  regTypeCC: any;
  constructor(
    private sharedDataService: SharedDataService,
    ) {}

  /**
   * validate method to check regType and return validation method
   * @param control
   */
  validate(control: AbstractControl) {
    this.regTypeCC = this.sharedDataService.getSessionData(enumValue.REG_TYPE_CC_KEY);
    if ( control.value && control.dirty && (this.regTypeCC === undefined) &&
      ( control.value.length !== 5 || !this.pattern.test(control.value))
    ) {
      return {'validatePolicyNumber': true};
    }
    if ( control.value && control.dirty && (this.regTypeCC === 'CC') &&
      ( control.value.length !== 9 || !this.pattern.test(control.value))
    ) {
      return {'validateSNNNumber': true};
    }
    return null;
  }
}
