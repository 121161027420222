import { Injectable } from '@angular/core';


@Injectable()
export class TealiumDataService {
  public events = [];

  udo = {};
 setDataLayer(config) {
    const view_id = config.locationurl;
    let pageName = '';
    if (view_id) {
      pageName = view_id.split('/').pop();
    }

    // will become new udo object
    this.udo = {
      'da_track': 'true',
      'page_category': 'pageview',
      'page_name': pageName,
      'page_type': pageName,
      'site_section': 'contactInfo',
      'view_id': view_id,
      'events': this.events,
      'site_name': 'Single Front Door',
    };
    this.events = [];
    return this.udo;

  }

   addPageLevelEvent(event) {
     if (event) {
       this.events.push(event);
     }
    }

addEvent(event) {
    if (typeof (<any>window)._trackAnalytics === 'function' && this.udo['events']) {
      this.udo['events'].push(event);
      const elementvars = {
        'da_track': event.da_track
      };
      const elementAttrs = JSON.parse(JSON.stringify(elementvars));
      this._appTrack(this.udo, null, elementAttrs);
      this.udo['events'] = [];
    } else {
      this.eventLag(event);
    }
  }
  eventLag(event) {
    setTimeout(() => {
      this.addEvent(event);
    }, 200);
  }
  //   addEvent(event) {
  //      this.events.push(event);
  //    this.udo['events'] = this.events;
  //   const elementvars = {
  //     'da_track': 'true' // new implementation
  //   };
  //   const elementAttrs = JSON.parse(JSON.stringify(elementvars));
  //   this._appTrack(this.udo, null, elementAttrs);

  // }

  _appTrack(data, element, elementAttrs) {
    try {
      // Handle automatic events without data-dl
      if ( elementAttrs == null) {
          if (typeof (<any>window)._trackEvents === 'function') {
              (<any>window)._trackEvents(data, element);
          }
      } else {
      if ( typeof (<any>window)._trackAnalytics === 'function') {
          (<any>window)._trackAnalytics(data, element, elementAttrs);
      }
      }
    } catch (e) {
        console.log('Tealium error : ', e.message);
    }
  }
  constructor() { }

}
