import {Component, Input, OnInit} from '@angular/core';
import {enumValue} from '../../../../constant.enum';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  hartFordLogo: string;
  enumValue = enumValue;
  @Input() pageTitle: string;

  constructor() {
  }

  ngOnInit() {
    if (window.location.hostname === 'account.thehartford.com') {
      this.hartFordLogo = 'https://s0.hfdstatic.com/sites/higux/v3.3.9/images/logo.svg';
    } else {
      this.hartFordLogo = 'https://s0.hfdstatic.com/sites/higux/v3.3.9/images/logo.svg';
    }
  }

  moveToHome() {
    // window.location.href = env.theHartford_url;
    window.location.href = 'https://www.thehartford.com/';
  }
}
