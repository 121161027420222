import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { env } from '../../../environments/environment-loader';
import { TokenUtility } from '../utils/tokenUtility';
import { enumValue } from '../../constant.enum';




@Injectable()
export class AuthGuard implements CanActivate {
  enumValue = enumValue;
  constructor(
    private router: Router,
) {}
canActivate(): boolean {
          //If CIAM session is not present in Cookie and EE session is present then redirect to CAIM Login page 
          //If EE session is not present in Cookie and CIAM  session is present then redirect to CIAM Login page
          //If EE session and CIAM  session is not present in Cookie then redirect to CIAM Login page  
          if (!(TokenUtility.getToken(enumValue.CIAM_SESSION_KEY) &&
             TokenUtility.getToken(enumValue.EE_SESSION_KEY)) )
           {
            window.location.href = env.gbCiamCommonUtil.ciamLoginURL;
            return false;
           } 
        return true;
      }

    }
