import { Component, OnInit, Input } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {enumValue} from '../../../../constant.enum';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  enumValue = enumValue;
  @Input() pageTitle: string;
  public footer_links: any;
  public copyright: any;
  public trademarks: string;
  public copyrightText: string;
  public year: number;

  constructor(private route: Router) { }

  ngOnInit() {
    const  originUrl = encodeURI( window.location.origin + '/contactUs');
    this.footer_links = [
      {'linkVal': originUrl, 'linkName': 'Contact Us'},
      {'linkVal': 'https://www.thehartford.com/online-privacy-policy', 'linkName': 'Privacy Policy'},
      {'linkVal': 'https://www.thehartford.com/data-privacy-opt-out-form', 'linkName': 'Your California Privacy Choices', 'linkImg': this.enumValue.PRIVACY_OPTIONS_ICON},
      {'linkVal': 'https://www.thehartford.com/legal-notice', 'linkName': 'Legal Notice'},
      {'linkVal': 'https://www.thehartford.com/accessibility-statement', 'linkName': 'Accessibility Statement'},
      {'linkVal': 'https://nebula-cdn.kampyle.com/direct/form.html?region=prodUsOregon&websiteId=283143&formId=14007',
       'linkName': 'Feedback'},
      ];
    this.copyright = {
      trademark: 'The Hartford. All rights reserved'
    };
    this.trademarks = this.copyright['trademark'];
    this.copyrightText = this.copyright['copyrightText'];
    this.year = new Date().getFullYear();
  }


}
