import {Component, OnInit} from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import {Router, NavigationEnd, RoutesRecognized} from '@angular/router';
import {enumValue} from '../../../../constant.enum';
import {SfdManagementService} from '../../../../shared/services/sfd-management.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  public currentUrl: any;
  public showCard = true;
  public enumValue = enumValue;
  public warningBannerFlag = false;
  public bannerContent = {
    'icon': '',
    'header': '',
    'message': ''
  };
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public sfdService: SfdManagementService) {
   /**  router.events
    .filter(event => event instanceof NavigationEnd)
    .subscribe((routeData: any) => {
     this.currentUrl = routeData.url;
     console.log('url', this.currentUrl);
     if (this.currentUrl === '/logout' || this.currentUrl === '/') {
       this.showCard = false;
     }
  }); **/
  }
  ngOnInit() {
    this.sfdService.toggleMsgRibbon.subscribe((val) => {
      this.warningBannerFlag = val;
    });
    this.sfdService.updateRibbonContent.subscribe((val) => {
      this.bannerContent.icon = val['icon'];
      this.bannerContent.header = val['header'];
      this.bannerContent.message = val['mesage'];
    });
  }

}
