import {Environment} from './environment';

export class EnvironmentLoader {

  static getEnvironmentFile(): any {
    const url = document.location.hostname;
    let dpUrl = '';
    const mockingSet = false;
    const clientId: Map<string, string> = new Map([
      ['auth', '1053'] // need the latest client id
    ]);
    const prodState = true;
    let agentLessToggle= false;
    let envName = 'local';
    let proxyState = false; // False = JBOSS & true = Proxy 45
    let ciamAccountUrl = '';
    let sfdAccountUrl = '';
    let ciamApiSgwUrl = '';

    /* We have to update the URLs below per the new site. */
    if (url.includes('ltd3-gbaccount')) {
     /// dpUrl = 'https://int-api.thehartford.com/uws/';
      dpUrl = 'https://int-api.thehartford.com/';
      envName = 'LTD3';
      ciamAccountUrl = 'https://account-dev.thehartford.com';
      sfdAccountUrl = 'https://ltd3-gbaccount.thehartford.com';
      proxyState = false;
      agentLessToggle= true;
      ciamApiSgwUrl = 'https://ciam-sgwdev.thehartford.com/';
    } else if (url.includes('ltd2-gbaccount')) {
      dpUrl = 'https://int-api.thehartford.com/';
      envName = 'LTD2';
      ciamAccountUrl = 'https://account-dev.thehartford.com';
      sfdAccountUrl = 'https://ltd2-gbaccount.thehartford.com';
      proxyState = false;
      agentLessToggle= true;
      ciamApiSgwUrl = 'https://ciam-sgwdev.thehartford.com/';
    } else if (url.includes('ltd-gbaccount')) {
      dpUrl = 'https://int-api.thehartford.com/';
      envName = 'LTD';
      ciamAccountUrl = 'https://account-dev.thehartford.com';
      sfdAccountUrl = 'https://ltd-gbaccount.thehartford.com';
      proxyState = false;
      agentLessToggle= true;
      ciamApiSgwUrl = 'https://ciam-sgwdev.thehartford.com/';
    } else if (url.includes('lqa-gbaccount')) {
      dpUrl = 'https://qa-api.thehartford.com/';
      envName = 'lqa';
      ciamAccountUrl = 'https://account-qa.thehartford.com';
      sfdAccountUrl = 'https://lqa-gbaccount.thehartford.com';
      proxyState = false;
      ciamApiSgwUrl = 'https://ciam-sgwqa.thehartford.com/';
      agentLessToggle= true;
    } else if (url.includes('lqa2-gbaccount')) {
      dpUrl = 'https://qa-api.thehartford.com/';
      envName = 'lqa2';
      ciamAccountUrl = 'https://account-qa.thehartford.com';
      sfdAccountUrl = 'https://lqa2-gbaccount.thehartford.com';
      proxyState = false;
      agentLessToggle= true;
      ciamApiSgwUrl = 'https://ciam-sgwqa.thehartford.com/';
    } else if (url.includes('lqa3-gbaccount')) {
      dpUrl = 'https://qa-api.thehartford.com/';
      envName = 'lqa3';
      ciamAccountUrl = 'https://account-qa.thehartford.com';
      sfdAccountUrl = 'https://lqa3-gbaccount.thehartford.com';
      proxyState = false;
      agentLessToggle= true;
      ciamApiSgwUrl = 'https://ciam-sgwqa.thehartford.com/';
    } else if (url.includes('qa3-gbaccount')) {
      dpUrl = 'https://qa-api.thehartford.com/';
      envName = 'QA3';
      ciamAccountUrl = 'https://account-qa.thehartford.com';
      sfdAccountUrl = 'https://qa3-gbaccount.thehartford.com';
      proxyState = false;
      agentLessToggle= true;
      ciamApiSgwUrl = 'https://ciam-sgwqa.thehartford.com/';
    } else if (url.includes('qa-gbaccount')) {
      dpUrl = 'https://qa-api.thehartford.com/';
      envName = 'QA';
      agentLessToggle= true;
      ciamAccountUrl = 'https://account-qa.thehartford.com';
      sfdAccountUrl = 'https://qa-gbaccount.thehartford.com';
      proxyState = false;
      ciamApiSgwUrl = 'https://ciam-sgwqa.thehartford.com/';
    } else if (url.includes('pte-gbaccount')) {
      dpUrl = 'https://qa-api.thehartford.com/';
      envName = 'pte';
      agentLessToggle= true;
      ciamAccountUrl = 'https://account-pte.thehartford.com';
      sfdAccountUrl = 'https://pte-gbaccount.thehartford.com';
      proxyState = false;
      ciamApiSgwUrl = 'https://ciam-sgwqa.thehartford.com/';
    } else if (url.includes('gbaccount')) {
      dpUrl = 'https://api.thehartford.com/';
      envName = 'prod';
      ciamAccountUrl = 'https://account.thehartford.com';
      sfdAccountUrl = 'https://gbaccount.thehartford.com';
      proxyState = false;
      agentLessToggle= true;
      ciamApiSgwUrl = 'https://ciam-sgw.thehartford.com/';
    } else {
      envName = 'local';
      const localCalls = false; // Change this for local dev
      if (localCalls) {
        dpUrl = 'int-api.thehartford.com/';
        envName = 'local';
        ciamAccountUrl = 'https://account-dev.thehartford.com';
        sfdAccountUrl = 'https://ltd-gbaccount.thehartford.com';
        proxyState = false;
        agentLessToggle= true;
        ciamApiSgwUrl = 'ciam-sgwdev.thehartford.com/'
      }
    }
    return new Environment(clientId, dpUrl, envName, mockingSet, prodState, proxyState, ciamAccountUrl, sfdAccountUrl, ciamApiSgwUrl, agentLessToggle).getEnv();
  }
}
export let env: any = EnvironmentLoader.getEnvironmentFile();
