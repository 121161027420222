import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,HttpClient,HttpResponse,HttpHeaders
} from '@angular/common/http'
import { Observable } from 'rxjs';
import { TokenUtility } from '../utils/tokenUtility';
import { enumValue } from '../../constant.enum';
import { tap } from 'rxjs/operators';
import { EnvironmentLoader,env } from '../../../environments/environment-loader';


/** Implemented for passing EEsession value in request header for all SFD API call's    */
@Injectable()
export class SFDIntercepter implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
     //  EESession(Authorization) value fetched from browser cookie
    const eeSession = TokenUtility.getToken(enumValue.EE_SESSION_KEY);
    let customReq=req
    //Check feature toggle is true/false
    if(env.agentLessToggle===true)
    {
      //adding authorization header during SFD  service call
      if(customReq.url.includes('/gb-sfd-service/sfd/v1/'))
      {
    // cloned headers, updated with new header (authorization) value.
      customReq = req.clone({
    headers: req.headers.set('Authorization', eeSession)});
      }
    } 
    return next.handle(customReq).pipe(tap(
      (response: HttpEvent<any>) => {
        //check response
        if (response instanceof HttpResponse) {
            return response;
        }
    })
    );
        }
      }