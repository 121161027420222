import {Component, Input, OnInit} from '@angular/core';
import {CustomPageLabelComponent} from '../../../sub-atomic/custom-page-label/custom-page-label.component';

@Component({
  selector: 'secondary-page-label',
  templateUrl: 'secondary-page-label.component.html',
  styleUrls: ['secondary-page-label.component.scss']
})
export class SecondaryPageLabelComponent extends CustomPageLabelComponent implements OnInit {

  @Input() iconURL: string;
  @Input()  elementId: string;
@Input() fontWeight: string;
@Input() higLabelText: string;
public splitTextFirst: any;
  public splitTextSecond: any;
  ngOnInit() {
    if (this.higLabelText) {
      this.splitTextFirst = this.higLabelText.split('.')[0] + '.';
      this.splitTextSecond = this.higLabelText.split('.')[1] + '.';

    }
  }
}
