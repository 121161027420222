<div style="position:relative;" >

<tertiary-page-label [errorCondition]=" isErrorText || (controls.touched  && (!focused || (focused && controls.dirty) || (!controls.valid))) && controls.errors"
                     [focusCondition]="focused"
                     [higLabel]="labelName"
                     [textId]="textIds"
                     labelColor="blue"
                     labelWeight="medium"
                     labelSize="smaller"
>
</tertiary-page-label>

<!-- Different Types of inputs -->
<primary-text-input
  [inputType]="textType"
  [inputId]="inputId"
  [inputPlaceholder]="inputPlaceholder"
  [maxlength]="maxlength"
  [minlength]="minlength"
  [max]="max"
  [readOnlyCondition]="readOnlyCondition"
  [unmaskedClass] = "unmaskeds"
  [inputValue]="defaultVaue"
  [errorCondition]="isErrorText || (controls.touched  && (!focused || (focused && controls.dirty) || (!controls.valid))) && controls.errors"

  [controls]="controls"
  [preRenderingLoad]="preRenderingLoad"
  (blurCondition)="onBlur($event)"
  (focusCondition)="onFocus($event)"
  [ariaLabel]="labelName"
  [labelName]="labelName"
  #{{inputId}}

  [inputSize]="inputSize"
  >
</primary-text-input>

<!-- put all options here -->
<!-- name them by the same key -->
<!-- visibility control by css? -->
<!--Validator goes here-->

<div class="no-padding" *ngIf="(controls.touched  && (!focused || (focused && controls.dirty) || (!controls.valid))) && controls.errors" >
  <span class="icon_box"><i class="fa fa-exclamation-circle"></i></span>
  <span class="error error_box">
 <!--<img class="icon-error"
         src='/assets/images/icons/white-x close.svg' alt="">-->
    {{ controls.errors && (controls.errors['required'] || controls.errors['minlength']) ? errorText:''}}
    {{ errorForDisplay(controls.errors) }}
  </span>
</div>
</div>
