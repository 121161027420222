import {env} from '../../../environments/environment-loader';

/**
 * TokenUtility handles the basic functionality with required tokens of myBenefits
 * Here there are a variety of generic functions to allow for flexibility in the future in the event
 * token management needs to be updated
 *
 * */

export class TokenUtility {

  private static EE_TOKEN = 'EESession';
  private static FR_TOKEN = 'CIAMSession';
  public static FR_TOKEN_LATEST = '';

  /* Generic Functionality */
  /**
   * remove token from cookies
   * @param cookieName
   */
  static clearToken(cookieName: string) {
    if (env.envName === 'local' || (env.envName === 'qa' && env.proxyState)) {
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    } else {
      document.cookie = cookieName + '=; domain=.thehartford.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // Domain Clearing
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // Any similar to the pathing. Caused Infinite loops
    }
  }

  /**
   * add token to cookies
   * @param cookieName
   * @param cookieValue
   */
  static addToken(cookieName: string, cookieValue: string) {
    if (env.envName === 'local' || (env.envName === 'qa' && env.proxyState)) {
      document.cookie = cookieName + '=' + cookieValue + '; path=/;';
    } else {
      document.cookie = cookieName + '=' + cookieValue + '; domain=.thehartford.com; path=/;';
    }
  }

  /**
   * to get token from cookies
   * @param cookieName
   */
  static getToken(cookieName: string): string {
    let bodyFrToken, token;
    if (document.cookie) {
      bodyFrToken = (document.cookie.split(';').find(x => x.includes(cookieName)));
      if (bodyFrToken) {
        token = bodyFrToken.split('=')[1];
      }
    }
    if (token != null && token.length > 0) {
      return token;
    } else {
      return '';
    }
  }

  /**
   * update token in cookies by calling addToken
   * @param cookieName
   * @param cookieValue
   */
  static updateToken(cookieName: string, cookieValue: string) {
    this.addToken(cookieName, cookieValue);
  }

  /**
   * remove my benefit - EE token
   */
  static removeMyBenefitsToken() {
    this.clearToken(this.EE_TOKEN);
  }
  /**
   * remove all my benefit - EE token
   */
  static removeAllMyBenefitsTokens() {
    this.clearToken(this.EE_TOKEN);
    this.clearToken(this.FR_TOKEN);
  }

  /**
   * add EE token
   * @param tokenValue
   */
  static addMyBenefitsToken(tokenValue: string) {
    this.addToken(this.EE_TOKEN, tokenValue);
  }

  /**
   * get forgerock token
   */
  static getExistingFrToken(): string {
    return this.getToken(this.FR_TOKEN);
  }

  /**
   * set forgerock token
   */
  static setCurrentFrToken(): void {
    this.FR_TOKEN_LATEST = this.getExistingFrToken();
  }

  /**
   * get existing forgerock token
   */
  static getExistingFrTokenState(): boolean {
    return (this.getToken(this.FR_TOKEN) != null && this.getToken(this.FR_TOKEN).length > 0);
  }

  /**
   * get existing my benefits - EE token
   */
  static getExistingMyBenefitsToken(): string {
    return this.getToken(this.EE_TOKEN);
  }

  /**
   * get existing my benefit token status
   */
  static getExistingMyBenefitsTokenState(): boolean {
    return (this.getToken(this.EE_TOKEN) != null && this.getToken(this.EE_TOKEN).length > 0);
  }

  /**
   * check if token is required or not as per env
   */
  static doRequiredTokensExist() {
    if ( env.mocking || env.envName === 'local') {
      return (true);
    } else {
      return this.getExistingFrTokenState() && this.getExistingMyBenefitsTokenState();
    }
  }

  /**
   * check if env is local or not, to get existing forgerock/EE token
   */
  static doEitherRequiredTokensExist() {
    if ( env.mocking || env.envName === 'local') {
      return (true);
    } else {
      return (this.getExistingFrTokenState()) || (this.getExistingMyBenefitsTokenState());
    }
  }

}
