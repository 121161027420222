import {Component, Input} from '@angular/core';
import {ButtonComponent} from '../../../sub-atomic/button/button.component';

@Component({
  selector: 'primary-button',
  templateUrl: 'primary-button.component.html',
  styleUrls: ['primary-button.component.scss']
})

export class PrimaryButtonComponent extends ButtonComponent {
  @Input() buttonColor = 'green';
  @Input() buttonLoading = 'done';
  @Input() buttonSize = '';
  @Input() textWeight = '';

  constructor() {
    super();
  }
  buttonClicked(event) {
    if ( event.target.dataset.disabled === 'true' ) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }
}
