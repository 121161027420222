/**
 * Created by AC12394 on 8/28/2017.
 */

import {Injectable} from '@angular/core';

export const FooterContent = {
  footer_links: [
    {'linkVal': 'https://www.thehartford.com/contact-the-hartford#collapse-1444624627226-1444624628009', 'linkName': 'Contact Us'},
    {'linkVal': 'https://www.thehartford.com/online-privacy-policy', 'linkName': 'Privacy Policy'},
    {'linkVal': 'https://www.thehartford.com/legal-notice', 'linkName': 'Legal Notice'},
    {'linkVal': 'https://www.thehartford.com/accessibility-statement', 'linkName': 'Accessibility Statement'},
    {'linkVal': 'https://www.thehartford.com/feedback-statement', 'linkName': 'Feedback'}

  ],
  copyright: {
    trademark: 'The Hartford. All rights reserved'
  }
};

@Injectable()
export class FooterContentService {

  getFooterContent(): any {
    return FooterContent;
  }
}





