<!--private radioText ='I am from Radiobtn';[radioText]="radioText"-->
<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 padding0">
    <span class="radiobtn" [ngClass]="checkSingleId ? 'left_pending':''">
      <input type="radio" id="radio-{{id}}" [attr.aria-label] ="radioText"  [name]="name ? name : 'selector'"
             [checked]="isSelected">
      <label for="radio-{{id}}" (click)="emit()" [ngClass]="checkSingleId ? 'left_margin':''">
         <tertiary-page-label
           [errorCondition]=" isErrorText"
           [focusCondition]="focused"
           labelColor="blue"
           [textId]="id"
             higLabel="{{radioText}}"
           radioButtonText = {{radioTextValue}}
           radioClass = {{radioCssValue}}
           radioclassText ={{radioSecondTextCssValue}}
           [checkSingle] = "checkSingleId"
           labelWeight="book"
           labelSize={{labelFontValue}}></tertiary-page-label>
      </label>
      <div id="select" class="check" [ngClass]="isErrorDate ? 'error-border':''" *ngIf="!checkSingleId"></div>
    </span>
  </div>
</div>


