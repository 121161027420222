import {Component, Input, OnInit} from '@angular/core';
import {NgSwitch} from '@angular/common';


@Component({
  selector: 'anchor-button',
  templateUrl: './anchor-button.component.html',
  styleUrls: ['./anchor-button.component.scss'],
  providers: [ NgSwitch ]
})
export class AnchorButtonComponent implements OnInit {
  @Input() path: string;
  @Input() text: string;
  @Input() color: string;
  @Input() type = 'url';
  @Input() btnId: string;

  public download;
  public isIE = false;
  constructor() { }

  ngOnInit() {
    if ( window.navigator.userAgent.indexOf( 'MSIE ' ) > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) ) {
      this.isIE = true;
    }
  }

}
