
import {Component, OnInit, Output, Input, EventEmitter, HostListener} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
@Component({
  selector: 'app-secondary-date-dropdown',
  templateUrl: 'secondary-date-dropdown.component.html',
  styleUrls: ['secondary-date-dropdown.component.scss']
})
export class SecondaryDateDropdownComponent implements OnInit {
  year = [];
  day = [];
  monthNames = [];
  public yearvalue;
  public mon;
  public noOfDays;
  public higLabelColor = false;
  public submitbtn = true;
  public newdayvalue;
  public defaultMonth;
  public selectedItem;
  public getSelectedDate;
  public currentyearloop;
  public selectedDay;
  public selectedYear: string;
  public showDateErrorMessage = true;
  public selectedMonth;
  // private lastDayWorkSection;
  public selectedDateDropdown: FormGroup;
  private disaplyBtn;
  public dateFormGroupName;
  @Input() dateLabelErrMsg;
  @Input() dateLabelId;
  @Input() dob_Id;
  @Input() showMessage;
  @Input() seconderyDateDropwonName;
  @Input() dropDowndateLabel;
  @Input() extensionYearCount: number;
  @Input() getServiceDate;
  public displayError;
  @Input() dateLabelName;
  @Output() selectedDOB: EventEmitter<string> = new EventEmitter();
  @Output() countChange: EventEmitter<Object> = new EventEmitter<Object>();
  private dateValue: {
        month: string;
        day: string;
        year: string
      };
    @Input() isErrorDate: any;
  // @HostListener('window:resize')
  // onResize() {
  //   this.monthDataPopulated();
  // }

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {
    this.dateFormGroupName = this.seconderyDateDropwonName;
    this.yearvalue = new Date().getFullYear();
    this.selectedDay = 'Day';
    this.monthDataPopulated();
    this.currentyearloop = new Date().getFullYear();
    for (let i = this.currentyearloop; i > this.currentyearloop - 101; i--) {
      if (i === new Date().getFullYear()) {
        this.year.push('Year');
      }
      this.year.push(i);
    }
    this.selectedItem = this.year[0];

    this.dateFormGroupName = this._formBuilder.group({
      getmonthNameval: ['Month'],
      getdayVal: ['Day'],
      getyearVal: [this.yearvalue]
    });
  }

  monthDataPopulated() {
    if (screen.width < 767) {
      this.monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul' ,
        'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    } else {
      this.monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];
    }
  }
  onChange(newMonth) {
    this.selectedDay = 'Day';
    this.selectedMonth = newMonth;
    if (this.selectedMonth !== 'Month') {
      this.day = [];
      if (this.isLeapYear(this.selectedYear)) {
        if (JSON.parse(this.selectedMonth) === 2) {
          this.noOfDays = new Date(this.yearvalue, this.selectedMonth, 0).getDate();
          for (let i = 0; i < this.noOfDays; i++) {
            this.day.push(i + 1);
          }
          this.day.push(29);
        } else {
          this.selectedMonth = newMonth;
          this.noOfDays = new Date(this.yearvalue, this.selectedMonth, 0).getDate();
          for (let i = 0; i < this.noOfDays; i++) {
            this.day.push(i + 1);
          }
        }
      } else {
        this.noOfDays = new Date(this.yearvalue, this.selectedMonth, 0).getDate();
        for (let i = 0; i < this.noOfDays; i++) {
          this.day.push(i + 1);
        }
        // if (JSON.parse(this.selectedMonth) === 2 && this.selectedYear !== 'Year') {
        //   this.day.push(29);
        // }
      }
    } else {
      this.day = [];
      this.selectedDay = 'Day';
    }
    if (this.selectedMonth && this.displayError === 2) {
      this.getDate();
      this.getUpdateDate();
    }
    this.setDOB();
  }

  onChangeYear() {
    this.selectedYear = this.selectedItem;
    if (this.isLeapYear(this.selectedYear)) {
      if (JSON.parse(this.selectedMonth) === 2) {
        this.day = [];
        this.noOfDays = new Date(this.yearvalue, this.selectedMonth, 0).getDate();
        for (let i = 0; i < this.noOfDays; i++) {
          this.day.push(i + 1);
        }
        this.day.push(29);
      }
      this.getSelectedDate = this.selectedDay;
      this.selectedDay = this.getSelectedDate;
    } else {
      this.day = [];
      this.noOfDays = new Date(this.yearvalue, this.selectedMonth, 0).getDate();
      for (let i = 0; i < this.noOfDays; i++) {
        this.day.push(i + 1);
      }
      if (JSON.parse(this.selectedMonth) === 2 && JSON.parse(this.selectedDay) === 29) {
        this.selectedDay = 'Day';
      }
      this.getSelectedDate = this.selectedDay;
      this.selectedDay = this.getSelectedDate;
    }
    if (this.yearvalue && this.displayError === 2) {
      this.getDate();
      this.getUpdateDate();
    }
    this.setDOB();
  }

  onChangeday(newday) {
    this.displayError = 2;
    this.newdayvalue = newday;
    this.newdayvalue = this.selectedMonth + '-' + this.newdayvalue + '-' + this.yearvalue;
    this.getDate();
    this.getUpdateDate();
    this.setDOB();
  }
  getDate() {
    let monthValue;
    let dayValue;
    if (9 >= this.selectedMonth) {
      monthValue = 0 + this.selectedMonth;
    } else {
      monthValue = this.selectedMonth;
    }
    if ( 9 >= this.selectedDay) {
      dayValue = 0 + this.selectedDay;
    } else {
      dayValue = this.selectedDay;
    }
    this.dateValue = {
      'month': monthValue,
      'day': dayValue,
      'year': this.yearvalue
    };
    if (this.dateValue.month && this.dateValue.day && this.dateValue.year) {
      this.countChange.emit(this.dateValue);
    } else {
      this.showDateErrorMessage = false;
      this.higLabelColor = true;
    }
}
getUpdateDate() {
  this. getDate();
}
  public backSpaceButtonDisabled(event): any {
    if (!event) {
      event = window.event;
    }
    const keyCode = event.keyCode;
    if (keyCode === 8) {
      if (navigator.userAgent.toLowerCase().indexOf('msie') === -1) {
        event.stopPropagation();
      } else {
        event.returnValue = false;
      }
      return false;
    }
  }
  isLeapYear(selectYear) {
    return ((selectYear % 4 === 0) && (selectYear % 100 !== 0)) || (selectYear % 400 === 0);
  }

  getDateFormat(val) {
    let selectedValue = val;
    if (selectedValue.toString().length < 2) {
      selectedValue = '0' + val;
    }
    return selectedValue;
  }
  isNullOrUndefined(val) {
    if (val === null || val === undefined) {
      return true;
    }
    else {
      return false;
    }
  }
  setDOB() {
    let DOB = this.selectedYear + '-' + this.getDateFormat(this.selectedMonth) + '-' + this.getDateFormat(this.selectedDay);
    if (this.isNullOrUndefined(this.selectedYear) || this.isNullOrUndefined(this.selectedMonth)
      || this.isNullOrUndefined(this.selectedDay) || this.selectedDay === 'Day' || this.selectedMonth === 'Month'
      || this.selectedYear === 'Year') {
      this.selectedDOB.emit(null);
    } else {
      this.selectedDOB.emit(DOB);
    }
    DOB = null;
  }
}
