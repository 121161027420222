

<tertiary-page-label [errorCondition]="isErrorDate"
                     [higLabel]="dateLabelName"
                     [textId]="dob_Id"
                     labelColor="blue"
                     labelWeight="medium"
                     labelSize="smaller"
>
</tertiary-page-label>

<div>
  <form [formGroup]="dateFormGroupName" id ='{{dateLabelId}}'>
    <div class="col-md-4 col-sm-4 col-xs-12 col-lg-4 no-padding ">
      <select aria-label="Select month" (change)="onChange($event.target.value)"
               formControlName="getmonthNameval"
               class="{{isErrorDate?'error-border':''}}" >
        <option selected>Month</option>
        <option *ngFor="let monthNames of monthNames,let i=index" [value]="i+1">{{monthNames}}</option>
      </select>
      <i class="fa fa-angle-down" [ngClass]="isErrorDate ? 'arrow-border':''"></i>
    </div>

    <div class="col-md-4 col-sm-4 col-xs-4 col-lg-4  dob-padding">
      <select aria-label="Select day" (change)="onChangeday($event.target.value)" formControlName="getdayVal"
               [(ngModel)]="selectedDay"  class="{{isErrorDate?'error-border':''}}">
        <option value="Day">Day</option>
        <option *ngFor="let day of day" [value]="day">{{day}}</option>
      </select>
      <i class="fa fa-angle-down" [ngClass]="isErrorDate ? 'arrow-border':''"></i>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-7 col-xs-offset-1 col-sm-offset-0 col-lg-4 dob-padding">
      <select aria-label="Select year" [(ngModel)]="selectedItem" (change)="onChangeYear()"
              formControlName="getyearVal"   class="{{isErrorDate?'error-border':''}}">
        <option *ngFor="let i of year" [ngValue]="i">{{i}}</option>
      </select>
      <i class="fa fa-angle-down" [ngClass]="isErrorDate ? 'arrow-border':''"></i>
    </div>
  </form>
</div>
<div class="clearfix"></div>

<!--<div *ngIf="showMessage" class='error col-sm-12' [hidden]="showDateErrorMessage">
  <span class="icon_box"><i class="fa fa-exclamation-circle"></i></span>&nbsp;  {{dateLabelErrMsg}}
</div>-->
