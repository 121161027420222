<div class="primary-container-label

  {{labelSize}}-label
  GothamSSm-{{labelWeight}}
  color-{{labelColor}}
">

    <img class="icon-svg" src="/assets/images/icons/claims_white.svg" alt="" *ngIf="faIcon">

  {{higLabel}}
</div>
