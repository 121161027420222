
import {filter} from 'rxjs/operators';
import { Component, OnInit, HostListener } from '@angular/core';
import {AuthService} from './auth.service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {SharedDataService} from './shared-data.service';

import {
  enumValue
} from './constant.enum';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
/**
 * classs representing a AppComponent
 */
export class AppComponent implements OnInit {
  public userType: any;
  public currentUrl: any;
  public showCard = true;
  enumValue = enumValue;

  /**
   * Create a AppComponent
   * @param sharedDataService
   * @param route
   * @param activatedRoute
   * @param authService
   */
  constructor(
              private sharedDataService: SharedDataService,
              private route: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) {
    this.sharedDataService.setSessionData('channelId', this.activatedRoute.snapshot.queryParams['channelId']);
  }
  title = ' GB Single Front Door Application';
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.authService.notifyUserAction();
  }
  ngOnInit() {
    this.route.events.pipe(
     filter(event => event instanceof NavigationEnd))
     .subscribe((routeData: any) => {
     this.currentUrl = routeData.url;
     if (this.currentUrl === enumValue.LOGOUT_PAGE_KEY || this.currentUrl === enumValue.CONTACTUS_PAGE_KEY || this.currentUrl === '/') {
       this.showCard = false;
     }
     });
    }
}
