
<app-inactivity-timer *ngIf="showCard"></app-inactivity-timer>
<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12 no-padding">
      <app-header>
      </app-header>

    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 pR7 pL7 app-container">
      <app-card>
        <router-outlet></router-outlet>
      </app-card>
    </div>
  </div>
  <div class="row ">
    <div class="col-xs-12 no-padding">
      <app-footer></app-footer>
    </div>
  </div>
</div>
