<div class="tertiary-container-label pT15" >
  <span *ngIf='higLabel'
                [id] = "textId"
                class="
                {{focusCondition?'focus-class':''}}
               {{errorCondition?'error-class':''}}
                {{labelDisabled? 'disabled' : ''}}
                {{labelSize}}-label
                GothamSSm-{{labelWeight}}
                color-{{labelColor}}
                {{radioClassCss}}
                {{labelSizeValue}}"
                [ngClass]="checkSingle ? 'checkSingle':''">
    {{sanitizeText(higLabel)}}
  </span>
  <span class="{{radioSecondTextCss}}">{{secondText}}</span>
</div>
