import {AfterContentInit, AfterViewInit, Component, Input, OnInit, } from '@angular/core';
import {FormControl} from '@angular/forms';
import {ElementControlFormWrapperComponent} from '../../sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';

@Component({
  selector: 'primary-form-text-input',
  templateUrl: 'primary-form-text-input.component.html',
  styleUrls: ['primary-form-text-input.component.scss']
})
export class PrimaryFormTextInputComponent extends ElementControlFormWrapperComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Input() unmaskeds: string;
  @Input() labelName: string;
  @Input() inputPlaceholder: string;
  @Input() textType: string;
  @Input() inputId: string;
  @Input() max: number;
  @Input() maxlength: string;
  @Input() minlength: string;
  @Input() min: string;
  @Input() inputValue: string;
  @Input() textIds: string;
  @Input() readOnlyCondition: boolean;
  @Input() inputSize?: string;
  @Input() preRenderingLoad = false;
  @Input() controls: FormControl = new FormControl();
  @Input() errorText: string;
  @Input() isErrorText: boolean;
  @Input() zipErrorText: any;
  @Input() defaultVaue: any;
// public isErrorText:boolean =true;
  ngOnInit() {
// console.log('controls', this.controls);
  }
  ngAfterViewInit() {
  }

  ngAfterContentInit() {

  }
}
