import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject , Subscription,  timer } from 'rxjs';
import { takeUntil, take ,  filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import {Router, NavigationEnd} from '@angular/router';
import {
  enumValue
} from './constant.enum';
@Component({
  selector: 'app-inactivity-timer',
  template: `
   <!-- <h1>User logout in:  {{ (minutesDisplay) }}:{{ (secondsDisplay) && (secondsDisplay <=59) ? secondsDisplay : '00' }}
    </h1> -->
  `,
  styles: []
})
export class InactivityTimerComponent implements OnDestroy, OnInit {
  enumValue = enumValue;
  public currentUrl: any;
  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime = 1;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;

  constructor(private authService: AuthService, private route: Router) {

  }

  ngOnInit() {
   // this.resetTimer();

    this.route.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((routeData: any) => {
         this.currentUrl = routeData.url;
        // console.log('url', routeData.url);
        if (this.currentUrl === enumValue.LOGOUT_PAGE_KEY || this.currentUrl === enumValue.CONTACTUS_PAGE_KEY || this.currentUrl === '/') {
          /** ----**/
        } else {
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }
          this.resetTimer();
        }
      });
    this.authService.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.currentUrl === enumValue.LOGOUT_PAGE_KEY || this.currentUrl === enumValue.CONTACTUS_PAGE_KEY || this.currentUrl === '/') {
        /** ----**/
      } else {
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
        }
        this.resetTimer();
      }
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetTimer(endTime: number = this.endTime) {
    const interval = 30000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
        this.render((duration - +value) * interval),
      err => { },
      () => {
        this.authService.logOutUser();
      }
    );
  }

  private render(count) {
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

}
