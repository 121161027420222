/*
 Session Management service for dealing with the "How" for saving
 information in the current state.
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';


@Injectable()
/**
 * classs representing a StorageManagementService
 */
export class StorageManagementService {

  isUserClaimsSet: BehaviorSubject<boolean> = new BehaviorSubject(false);
  setIsUserClaimsSetFlag(isUserClaimsSet: boolean) {
    this.isUserClaimsSet.next(isUserClaimsSet);
  }

  getIsUserClaimsSetFlag() {
    return this.isUserClaimsSet;
  }

  constructor() {
  }
  /**
   *
   * @param objToSetKey {string}
   * @param ObjToSetValue {any}
   */
  setSessionObject(objToSetKey: string, ObjToSetValue) {
    sessionStorage.setItem(objToSetKey,
      JSON.stringify({[objToSetKey]: ObjToSetValue}));
  }

  /**
   *
   * @param objToSetKey {string}
   * @param ObjToSetValue {any}
   */
  setSessionObjectNoJSON(objToSetKey: string, ObjToSetValue) {
    sessionStorage.setItem(objToSetKey,
      ObjToSetValue);
  }

  /**
   * removeSessionObject() use to remove data based on key
   * @param objToSetKey {string}
   */
  removeSessionObject(objToSetKey) {
    sessionStorage.removeItem(objToSetKey);
  }

  /**
   * retrieveSessionObject() use to get data based on key
   * @param objToSetKey {string}
   */
  retrieveSessionObject(objToSetKey) {
    if (sessionStorage.getItem(objToSetKey) !== null) {
      return sessionStorage.getItem(objToSetKey);
    } else { return null; }                               /*Service must return null if key doesn't exist*/
  }

  /**
   * isSessionStorageEmpty() use to check boolean based on data length in storage
   * @return boolean
   */
  isSessionStorageEmpty(): boolean {
    if (sessionStorage.length === 0) { return true; }
    return false;
  }

  /**
   * setLocalObject() use to set data object as a string
   * @param objToSetKey {string}
   * @param ObjToSetValue {any}
   */
  setLocalObject(objToSetKey: string, ObjToSetValue) {
    localStorage.setItem(objToSetKey,
      JSON.stringify({[objToSetKey]: ObjToSetValue}));
  }

  /**
   * removeLocalObject() use to remove object based on key
   * @param objToSetKey {string}
   */
  removeLocalObject(objToSetKey) {
    localStorage.removeItem(objToSetKey);
  }

  /**
   * retrieveLocalObject() use to get object data based on key
   * @param objToSetKey {string}
   * @return objectData {object}
   */
  retrieveLocalObject(objToSetKey) {
    return localStorage.getItem(objToSetKey);
  }

  /**
   * getDecodedContent () to decode content
   * @param content {any}
   * @return {any}
   */
  getDecodedContent(content) {
    const keyArray = Object.keys(content);
    for (let i = 0; i < keyArray.length; i++) {
      content[keyArray[i]] = decodeURIComponent(content[keyArray[i]]).replace(/\+/g, ' ');
    }
    return content;
  }

  /**
   * setClaimsFlag() to set flag
   * @param reload
   */
  setClaimsFlag(reload) {
    const sessionObj = JSON.parse(this.retrieveSessionObject('appData')).appData;
    sessionObj.reloadClaims = reload;
    this.setSessionObject('appData', sessionObj);
  }

  /**
   * getClaimsFlag () to get data
   * @return {any}
   */
  getClaimsFlag() {
    const sessionObj = JSON.parse(this.retrieveSessionObject('appData')).appData || null;
    return sessionObj.reloadClaims || null;
  }

  /**
   * setUserData () to set user data
   * @param user {object}
   */
  setUserData(user) {
    const sessionObj = JSON.parse(this.retrieveSessionObject('appData')).appData;
    sessionObj.userData = user;
    this.setSessionObject('appData', sessionObj);
  }
  }
