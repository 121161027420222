import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl} from '@angular/forms';

@Component({
  selector: 'element-control',
  templateUrl: 'element-control.component.html'
})
export class ElementControlComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnChanges {

  /* Parameters */
  private innerValue: any = '';
  private errors;
  public focused;
  private blurred;
  @Input() controls: FormControl = new FormControl();
  @Input() errorCondition: boolean;
  @Output() blurCondition = new EventEmitter<boolean>();
  @Output() focusCondition = new EventEmitter<boolean>();
  @Output() changeCondition = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {}
  ngOnChanges() {
   // console.log("In NG on Changes");
   // console.log(this.controls.value);
  }


  onError(errorList: object[]) {
    this.errors = errorList;
  }

  onFocus(focused?: boolean) {
    // console.log("Focused!");
    this.focused = focused;
    this.focusCondition.emit(true);
  }

  hasChanged(changed?: boolean) {
   // console.log("Changes!");
   // console.log(this.controls.value);
    this.changeCondition.emit(true);
  }

  onBlur(blurred?: boolean) {
  //  console.log("Blur!");
    this.blurred = true;
    this.blurCondition.emit(true);

  }

  // propagate changes into the custom form control
  propagateChange = (_: any) => { };

  // From ControlValueAccessor interface
  writeValue(value: any) {
    this.innerValue = value;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {

  }

}
