export enum enumValue {
  CIAM_SESSION_KEY = 'CIAMSession',
  EE_SESSION_KEY = 'EESession',
  SUCCESS_STATUS = 200,
  SITE_ACCESS_KEY = 'siteAccess',
  SITE_ACCESS_KEY_CXR = 'siteAccessCXR',
  USER_INFO_KEY = 'userInfoData',
  VALIDATION_METHOD_KEY = 'validationMethods',
  SSO_PARTY_REFID_KEY = 'ssoPartyRefId',
  SSO_NEW_REGISTRATION_ID_KEY = 'ssoNewRegInd',
  PARTY_REFID_KEY = 'partyRefId',
  NEW_REGISTRATION_ID_KEY = 'newRegistrationInd',
  CIAM_TOKENS_KEY = 'ciamTokens',
  TARGET_ENV_KEY = 'target_env',
  RESPONSE_TYPE_KEY = 'responsetype',
  CASE_ID = 'caseId',
  CHANNEL_ID_KEYS = 'channelid',
  SESSION_IDENTIFIER_KEY = 'sessionIdentifier',
  SESSION_IDENTIFIERS_KEY = 'sessionIdentifier',
  DEBUG_TEXT_KEY = 'debug',
  ERROR_TEXT_KEY = 'error',
  INFOS_TEXT_KEY = 'info',
  ERROR_MESSAGE = 'Sites array is empty for this  user',
  TOKEN_TYPE_KEY = 'tokenType',
  PROFILEID_TYPE_KEY = 'profileId',
  PHONE_ICON_KEY = 'phone icon',
  SSO_ONLY_ICON_KEY = 'sso only icon',
  CASE_EXCLUSION_ICON_KEY = 'case exclusion icon',
  HIG_LOGO_KEY = 'HIG Logo',
  VERI_SIGN_KEY  = 'Norton Secured - Powered by VeriSign',
  CHANNEL_ID_KEY = 'channelId',
  CHANNEL_ID_PARAM_KEY = '?channelId=',
  PROFILE_UPDATE_PARAM_KEY = '?profileUpdate=',
  PASSWORD_UPDATE_PARAM_KEY = '?passwordUpdate=',
  USER_DATA_KEY = 'userData',
  CONTENT_TYPE_KEY = 'Content-Type',
  APPLICATION_KEY = 'application/json',
  SS0_TOKEN_KEY = 'ssotoken',
  INTERNAL_TOKEN_KEY = 'internaltoken',
  TOKENS_KEY = 'token',
  GOTO_KEY = 'goto',
  EMAIL_TOKEN_PARAM= 'emailTokenParam',
  AUTH_TOKEN = 'authToken',
  EMPLOYER_CODE_KEY = '9995',
  EMPLOYE_CODE_KEY = '2791',
  OPT_THAA_KEY = '2',
  OPT_MB_KEY = '1',
  PERSON_ID_KEY = 'PersonId',
  USER_TYPES_KEY = 'UserType',
  PROFILE_UPDATE_KEY = 'profileUpdate',
  PASSWORD_UPDATE_KEY = 'passwordUpdate',
  US_BANK_USER_CASE_KEY = '52410',
  CONTACTUS_PAGE_KEY = '/contactUs',
  CUSTOM_ERROR_PAGE_KEY= '/unauthorized',
  CHANNELID_ERROR = 'channel id sent but with no auth token',
  LOGOUT_PAGE_KEY = '/navigation/logout',
  REGISTER_PAGE_KEY = '/signup/register',
  MULTI_PAGE_KEY = '/navigation/multiredirect',
  ACCOUNT_LOCKED_KEY = '/privilegeUser/accountLocked',
  PASS_CODE_KEY = '/privilegeUser/passCode',
  VERIFICATION_PAGE_KEY = '/signup/verification',
  REG_TYPE_MB_KEY = 'regTypeMB',
  REG_TYPE_ER_KEY = 'regTypeER',
  REG_TYPE_CC_KEY = 'regTypeCC',
  REGISTRATION_DATA_KEY = 'registrationData',
  ACCESS_TOKEN_KEY = 'access_token',
  ACCESS_TOKENS_KEY = 'accessToken',
  TOKEN_TYPE_TEXT_KEY = 'tokenType',
  PASS_CODE_TEXT_KEY = 'passCode',
  TOKEN_KEY = 'token_type',
  ERROR_MESSAGE_KEY = 'message',
  PROFILEID_KEY = 'profileId',
  THAAID_KEY = 'thaaId',
  CXR_URL_KEY = 'getcxrUrlArray',
  EMAIL_PROCESS_KEY = 'emailProcessData',
  MB_KEY = 'MB',
  CC_KEY = 'CC',
  ER_KEY = 'ER',
  EU_KEY = 'EU',
  EM_KEY = 'EM',
  BEARER_KEY = 'Bearer',
  ERROR_LIST_KEY = 'errorList',
  SITES_ARRAY_ERROR_LIST_KEY= 'sitesArrayErrorList',
  EXPIRED_TOKEN_CODE = '400',
  INTERNAL_SERVER_ERROR_CODE = 500,
  VALIDATION_LIST_KEY = 'validationMethodList',
  REG_TYPE_KEY = 'regType',
  REG_STATUS_CODE = 'regStatusCd',
  GB_USER_KEY = 'gbUser',
  THAA_USER_KEY = 'thaaUser',
  SITES_KEY = 'sites',
  SITE_KEY = 'site',
  PERSON_AUTH_LEY = 'ProcessPersonAuth',
  SESSION_TIMEOUT_KEY = 'isSessionTimedOut=success',
  LOGOUT_KEY = '&isLogout=success',
  GOTO_PARAM_KEY = '&goto=',
  GOTO_PARAMS_KEY = '?goto=',
  BENEFITS_TEXT_KEY = 'What benefits do you want to check?',
  PERSONAL_CLAIM_KEY = 'I need to check on my own personal claim, submit a new claim/leave, or see payment information',
  EMPLOYER_SELECTED_KEY = 'radio-employer',
  EMPLOYEE_SELECTED_KEY = 'radio-employee',
  EMPLOYEE_PLAN_KEY = 'I need to manage employee benefits plan',
  ACCESS_TEXT_KEY = 'What kind of access do you need?',
  EMPLOYEE_PhoneNo_KEY = '8662947987',
  EMPLOYER_PhoneNo_KEY = '8005496514',
  DONE_KEY = 'done',
  LOADING_KEY = 'loading',
  SSN_KEYS = 'Last 5 Digits of SSN',
  SSN_TEXT_KEY = 'SSN',
  EMPID_TEXT_KEY = 'Employee ID',
  MIN_LENGTH_KEY = '1',
  MAX_LENGTH_KEY = '15',
  RADIO_SSN_KEY = 'radio-ssn',
  RADIO_EMPID_KEY = 'radio-empid',
  RADIO_INSURED_KEY = 'radio-insured',
  SSN_FIELD_KEY = 'ssnId',
  GBX_UNMASKED = 'gbx-unmasked',
  SSN_FIELD_VALUE_KEY = 'ssnFieldValue',
  EMPID_FIELD_VALUE_KEY = 'empidFieldValue',
  EMPID_FIELD_KEY = 'empId',
  DATE_OF_BIRTH = 'dateOfBirth',
  INSUREDID_FIELD_VALUE_KEY = 'insuredFieldValue',
  DOB_FIELD_VALUE_KEY = 'birthDate',
  INSUREDID_FIELD_KEY = 'insuredId',
  INS_ID_KEY = 'INSID',
  SSN_KEY = 'SSN-5',
  ALT_ID_KEY = 'ALTID',
  USER_ERROR_CODE_KEY = '04',
  ERROR_CODE_KEY = '01',
  US_ERROR_CODE_KEY = '02',
  US_ERROR_CODE_CIAM_KEY = '102',
  EMPLOYEEID_MULTICASE_ERROR_CODE_KEY = '32',
  ERROR_MESSAGE_TEXT_KEY = 'Excluded case error',
  US_ERROR_MESSAGE_TEXT_KEY = 'SSO only case error',
  SUCCESS_KEY = 'success',
  SSN_ERROR_TEXT_KEY = 'Last 5 digits of Social Security Number',
  SSN_ERRORS_TEXT_KEY = '9 digits of Social Security Number',
  EMAIL_ERROR_KEY = 'It looks like that didn\'t work. Please double-check your information and try again.',
  EMAIL_TEXT_KEY= 'You already registered with a different email. Please',
  EDIT_TEXT_KEY = ', then edit your contact information on your Profile.',
  EMPLOYER_CARE_KEY = 'It looks like your employer dosn\'t allow online access. For help with a claim, please call 1-800-549-6514.',
  US_BANK_ERROR_MESSAGE_TEXT = 'It looks like your employer only allows access through single sign-on. Visit your employer\'s website to access your benefits information, or call 1-877-778-1383 M-F 8AM-8PM ET.',
  LOGIN_ERROR_TEXT_KEY = '. Want to change it? Log in first with ',
  VERIFICATION_HEADING_TEXT_KEY = 'Great, we found you! We just need one more thing to verify your identity.',
  VERIFICATION_TEXT_KEY = 'Select Verification Method:',
  VERIFICATION_KEY = 'Verification Method:',
  INFO_TEXT_KEY = 'Why are you asking me this information?',
  ACCOUNT_INFO_TEXT_KEY = 'Your privacy is important. Providing this information helps us verify that it\'s really you trying to get into your account.',
  SIGN_UP_INFO_TEXT_KEY = 'View your benefits online any time with a free account. We just need a little information to get started.',
  LAST_NAME_KEY = 'Last Name',
  LAST_NAME_TEXT_KEY = 'LastNameId',
  LAST_NAME_ID_KEY = 'lastName',
  DATE_TEXT_KEY = 'Please enter a valid date.',
  DOB_TEXT_KEY = 'Date of Birth',
  SIGN_UP_DOB_KEY = 'date_of_birth',
  ZIP_CODE_TEXT_KEY = 'Zip Code',
  PIN_CODE_TEXT_KEY = 'Pin Code',
  ZIP_CODE_ID_KEY = 'ZipCodeId',
  ZIP_CODE_KEY = 'zipCode',
  EMPLOYER_TEXT_KEY = 'I need to manage an employee benefits plan',
  EMPLOYEE_KEY = 'I need to check on my own personal claim, submit a new claim/leave, or see payment information',
  SIGN_UP_TEXT_KEY = 'Sign Up',
  SSN_MAX_LENGTH = 5,
  SSN_MAX_LENGTHS = 9,
  ZIP_MAX_LENGTH = 5,
  PIN_CODE_MAX_LENGTH = 4,
  PIN_CODE_MIN_LENGTH = 4,
  COMPLETE_REGISTRATION_TEXT_KEY  = 'Complete Registration',
  LOG_TEXT_KEY =  'log in',
  NEXT_TEXT_KEY = 'Next',
  TALK_TEXT_KEY = 'Let\'s Talk Instead.',
  ACCOUNT_LOCKED = 'Account Is Locked',
  LOGGING_TEXT_KEY = 'Having trouble logging in?',
  PHONE_TEXT_KEY = 'Phone: ',
  AVAILABLE_TEXT_KEY = 'Availability: ',
  TIMING_TEXT_KEY = 'Monday - Friday 8AM - 8PM EST',
  CLAIMS_TEXT_KEY  = 'Questions about your claims?',
  USER_VALIDATION_KEY = 'userValidationKey',
  TIME_OUT_TIMER_KEY = 2000,
  ACCOUNT_LOCKED_TEXT = 'You entered the wrong registration code too many times. For security, we temporarily locked your account. To unlock it, please contact the person who set up your system access, or call us at <span class="hidden-xs visible-sm-inline visible-md-inline visible-lg-inline">800-523-2233</span><span class="visible-xs-inline hidden-sm hidden-md hidden-lg"><a href="tel:800-523-2233">800-523-2233</a></span>.',
  PASS_CODE_ERROR = 'Pass Code',
  WARNING_ICON = './../assets/images/icons/Warning.svg',
  INCORRECT_CODE = 'Incorrect Registration Code',
  WRONG_CODE_TEXT = 'You entered the wrong code. Try entering it again. If you’re unsure of your code, please check with whomever set up your access.',
  PLAN_ADMIN = 'Plan Administrator Setup',
  ENTER_REG_CODE = 'Enter your registration code. If you have not received a code, please contact the person who set up your system access.',
  REG_CODE = 'Registration Code',
  SET_THIS_TEXT = 'Would You Rather Set This Up Later?',
  SKIP_INFO_TEXT = 'You can skip the Plan Administrator setup and see your claims now. You can finish the setup later.',
  SKIP_LINKS = 'Skip Setup And Go To My Claims',
  ACC_LOCKED_ICON = '../../../assets/images/icons/Alert-Not-Authorized.svg',
  RETURN_LOGIN_BTN = 'Return to Login',
  EXPIRED_INFO_TEXT = 'Your registration code has expired. You’ll need a new registration code to set up your plan administrator account. Please call us to get your new registration code.',
  DEFAULT_STATUS_CODE = '99',
  SUCCESS_STATUS_CODE = '100',
  ERROR_STATUS_CODE = '101',
  LOCKED_STATUS_CODE = '102',
  PLEASE_TRY_AGAIN = 'Please Try Again',
  CHECK_AGAIN = 'It looks like that didn’t work. Please double-check your code and try again. If you’re still having trouble, call us at <span class="hidden-xs visible-sm-inline visible-md-inline visible-lg-inline">1-866-294-7987</span><span class="visible-xs-inline hidden-sm hidden-md hidden-lg"><a href="tel:1-866-294-7987">1-866-294-7987</a></span>.',
  PHONE_ICON = './../assets/images/icons/Phones.png',
  VETTING_FLAG = 'vettingFlg',
  PASS_RES = 'passCodeResp',
  ACC_LOCKED_CODE = '1001',
  INVALID_CODE = '1002',
  VALID_CODE = '1003',
  ALREADY_REGISTERED = '1004',
  PASS_SER_FAIL_CODE = '101',
  ACT_REG = 'accntClaim',
  REGISTERED = 'Y',
  REGISTRATION_CD = 'regStatus',
  ER_CODE = 'ER',
  REG_TYPE_EU_KEY = 'regTypeEU',
  REG_TYPE_EM_KEY = 'regTypeEM',
  REGISTER_USER = 'registeredUser',
  INFO_TITLE = 'What benefits do you want to check?',
  COMPANIES_SECTION_TITLE = 'Which company is this for?',
  CLAIM_SECTION_TITLE = 'Which date is this claim for?',
  CLAIM_SUBMITTED_TEXT = 'Any disability claim submitted',
  LEAVE_ACTIVITY = ' or any leave activity regardless of date',
  HIG_LECACY_lIST = 'higLegacyList',
  CONFIRMATION_TEXT_KEY = 'You’re Done!',
  CONFIRMATION_BUTTON_TEXT_KEY_NEXT = 'Go To New Experience',
  CONFIRMATION_BUTTON_TEXT_KEY = 'Visit The Resource Center',
  CONFIRMATION_BODY_TEXT = 'You’re all set to use the new experience. You can visit the resource center to learn more about the site or go directly to the new experience',
  CONFIRMATION_PAGE_URL_KEY = 'https://www.thehartford.com/advantage-center',
  CONFIRMATION_PAGE_KEY = '/confirmationPage',
  SSO_ONLY_HEADING_TEXT_KEY= 'Use Your Employer\'s Intranet',
  SSO_ONLY_SUB_HEADING_TEXT_KEY= 'Your Employer only allows access to our site through their company portal. Log on to a work computer and use your company\'s intranet to access our site.',
  CASE_EXCLUSION_HEADING_TEXT_KEY= 'Contact Your Employer',
  CASE_EXCLUSION_SUB_HEADING_TEXT_KEY= 'It looks like your employer doesn\'t allow online access. Contact your supervisor or your HR department for information about how to acess your benefits information.',
  CASE_EXCLUSION_KEY= 'S001',
  SSO_ONLY_ERROR_KEY= 'S002',
  PRIVACY_OPTIONS_ICON= '../../../../../../assets/images/icons/privacyoptions.svg'
}
