import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SfdManagementService} from './shared/services/sfd-management.service';
import { env } from '../environments/environment-loader';
import {enumValue} from './constant.enum';
import {HttpHeaders, HttpClient} from '@angular/common/http';

@Injectable()
export class SharedDataService {

  constructor(private sfdManagementService: SfdManagementService,
              private httpClient: HttpClient) { }
  private profileId = new BehaviorSubject('12');
  private verificationMethod = new BehaviorSubject('');
  private caseContactsEmailVerificationMethod = new BehaviorSubject('');
  private emailVerificationMethod = new BehaviorSubject('');
  private sitesData = new BehaviorSubject('');
  private isLogout = new BehaviorSubject('false');
  private expiredTokenStatus = new BehaviorSubject(false);
  private thaaErStatus = new BehaviorSubject(false);
  private checkdSiteArray = new BehaviorSubject(false);
  private checkChannelId = new BehaviorSubject('');
  public sfdSubDomain = (env.gbCiamCommonUtil.SfdAccountUrl).split('/')[2];
  public  errorCode = new BehaviorSubject(false);
 /**
   * getErrorCode() use to get errorCode
   */
  getErrorCode(): Observable<any> {
    return this.errorCode.asObservable();
  }

  /**
   * setErrorCode() use to set errorCode
   * @param id {number}
   */
  setErrorCode(id: any) {
    this.errorCode.next(id);
  }

  /**
   * getchannelId() use to get channelID
   */
  getchannelId(): Observable<any> {
    return this.checkChannelId.asObservable();
  }

  /**
   * setchannelId() use to set channelId
   * @param id {number}
   */
  setchannelId(id: any) {
    this.checkChannelId.next(id);
  }
  /**
   * getIsAutoLogout() use to check user logout or not based on flag
   * @returns {Observable<any>}
   */
  getIsAutoLogout(): Observable<any> {
    return this.isLogout.asObservable();
  }

  /**
   * Used to get whether token is expired or not
   */
  getExpiredTokenStatus(): Observable<boolean> {
    return this.expiredTokenStatus.asObservable();
  }

  /**
   * Used to set whether toekn is expired or not
   * @param tokenStatus
   */
  setExpiredTokenStatus(tokenStatus: boolean) {
    this.expiredTokenStatus.next(tokenStatus);
  }

  /**
   * Used to get ER token status
   */
  getThaaErStatus(): Observable<boolean> {
    return this.thaaErStatus.asObservable();
  }

  /**
   * used to set RE token status
   * @param thaaErStatus
   */
  setThaaErStatus(thaaErStatus: boolean) {
    this.thaaErStatus.next(thaaErStatus);
  }

  /**
   * used to get the check whether to check sites array or not
   */
  getCheckdSiteArray(): Observable<boolean> {
    return this.checkdSiteArray.asObservable();
  }

  /**
   * used to set the check whether to check sites array or not
   * @param checkdSiteArray
   */
  setCheckdSiteArray(checkdSiteArray: boolean) {
    this.checkdSiteArray.next(checkdSiteArray);
  }
  /**
   * setIsAutoLogout() use to set logout or not based on flag
   * @param id
   */
  setIsAutoLogout(id: any) {
    this.isLogout.next(id);
  }
  /**
   * TO set verification method
   */
  getCaseContactsEmailVerificationMethod(): Observable<any> {
    return this.caseContactsEmailVerificationMethod.asObservable();
  }
  setCaseContactsEmailVerificationMethod(userData: any) {
    this.caseContactsEmailVerificationMethod.next(userData);
  }
  /**
   * TO set verification method
   */
  getEmailVerificationMethod(): Observable<any> {
    return this.emailVerificationMethod.asObservable();
  }
  setEmailVerificationMethod(userData: any) {
    this.emailVerificationMethod.next(userData);
  }
  /**
   * TO set verification method
   */
  getVerificationMethod(): Observable<any> {
    return this.verificationMethod.asObservable();
  }
  setVerificationMethod(id: any) {
    this.verificationMethod.next(id);
  }
  /**
   * getProfileId() use to get profileId
   * @returns {Observable<any>}
   */
  getProfileId(): Observable<any> {
    return this.profileId.asObservable();
  }

  /**
   * setProfileId() use to set profileID for user
   * @param id
   */
  setProfileId(id: any) {
    this.profileId.next(id);
  }

  /**
   * getSitesData() use to get site data based on key
   * @returns {Observable<any>}
   */
  getSitesData(): Observable<any> {
    return this.sitesData.asObservable();
  }

  /**
   * setSitesData() use to set sites data
   * @param sites {any}
   */
  setSitesData(sites: any) {
    this.sitesData.next(sites);
  }
  /**
   * setSessionObject() use to set session data based on key
   * @param objToSetKey
   * @param ObjToSetValue
   */
  setSessionObject(objToSetKey: string, ObjToSetValue) {
    sessionStorage.setItem(objToSetKey,
      JSON.stringify({[objToSetKey]: ObjToSetValue}));
  }

  /**
   * retrieveSessionObject() use to getobjectData based on key
   * @param objToSetKey
   * @returns {any}
   */
  retrieveSessionObject(objToSetKey) {
    if (sessionStorage.getItem(objToSetKey) !== null) {
      return sessionStorage.getItem(objToSetKey);
    } else { return null; }                               /*Service must return null if key doesn't exist*/
  }

  /**
   * setSessionData() use to set session data based on  key
   * @param key {string}
   * @param value {string}
   */
  setSessionData(key: any, value: any) {
    if (!this.retrieveSessionObject('sessionData')) {
      this.setSessionObject('sessionData', {});
    }
    const sessionData = JSON.parse(this.retrieveSessionObject('sessionData')).sessionData;
    sessionData[key] = value;
    this.setSessionObject('sessionData', sessionData);

  }

  /**
   * getSessionData() use to get session data based on key
   * @param key
   * @returns {any}
   */
  getSessionData(key) {
    if (this.retrieveSessionObject('sessionData')) {
      const sessionData = JSON.parse(this.retrieveSessionObject('sessionData')).sessionData;
      return sessionData[key];
    } else {
      return null;
    }
  }
  /**
   * isNumber(evt): this method is use to allow only number in input box
   * @param evt
   * @returns {boolean}
   */
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  /**
   * checkSpace(e): this method is use to not allow space in input box
   * @param e
   * @returns {boolean}
   */
  checkSpace(e) {
    if ((e.which === 32) || (e.which === 45)) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param e
   */
  checkHyphen(e) {
    if (e.which === 45) {
      return false;
    }
    return true;
  }
  /**
   * getExistingForgeRockToken () this method is use to get cookie valuse based on key passed as parameter in method
   * @param cookie {string}
   * @param key {string}
   * @returns {any}
   */
  getExistingForgeRockToken(cookie, key) {
    let bodyFrToken, frToken;
    if (cookie) {
      bodyFrToken = (cookie.split(';').find(x => x.includes(key)));
      if (bodyFrToken) {
        frToken = bodyFrToken.split('=')[1];
      }
    }
    return frToken;
  }

  /**
   * getExistingForgeRockTokens() use to token from cookie base don key
   * @param cookie
   * @param key
   * @returns {any}
   */
  getExistingForgeRockTokens(cookie, key) {
    let bodyFrToken, frToken;
    if (cookie) {
      bodyFrToken = (cookie.split(';').find(x => x.includes(key)));
      if (bodyFrToken) {
        frToken = bodyFrToken;
      }
    }
    return frToken;
  }
  /**
   * bake_cookie () this method is use to set value inside cookie with key name
   * @param name {string}
   * @param value {any}
   */
  bake_cookie(name, value) {
    const cookie = [name, '=', JSON.stringify(value), '; path=/;domain=' + this.sfdSubDomain].join('');
    document.cookie = cookie;
  }
  bake_cookies(name, value) {
    const cookie = [name, '=', value, '; path=/;domain=.thehartford.com;'].join('');
    document.cookie = cookie;
  }
  /**
   * clearCookie () this method is use to clear cookie value based on key name
   * @param {string} cookieName
   */
  clearCookie(cookieName: string) {
    document.cookie = '' + cookieName + '=; domain=.thehartford.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  /**
   * setUrlParameter () this method is use to set parameter with URL and return completer URl with parameter
   * @param url {string}
   * @param params {string}
   * @returns {string}
   */
  setUrlParameter(url, params) {
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    const baseUrl = url.split('?')[0];
    const  paramsData = '?' + queryString;
    return baseUrl + paramsData;
  }
  /**
   * validatePhone()  this method is use to formate phone into 1-XXX-XXX-XXXX
   * @param phoneField {number}
   */
  validatePhone(phoneField) {
      const phoneNumber = '1' + '-' + + phoneField.substring(0, 3)  + '-' + phoneField.substring(3, 6) + '-' + phoneField.substring(6);
      return phoneNumber;
  }
  /**
   * logsDataStatus ()  to check status of responce based on token
   * @param logText {string}
   * @param debugLevel {string}
   */
  logsDataStatus(logText, debugLevel, ciamToken) {
    this.sfdManagementService.logServiceAPI(logText, debugLevel, ciamToken).subscribe(
      data => {
      }, (error) => {}
    );
  }

  setAuthorizeResponse(authorize): Observable<Object> {
    const request = JSON.stringify({
      'authorize' : authorize
    });
    const headers = new HttpHeaders().set(enumValue.SSO_PARTY_REFID_KEY, enumValue.NEW_REGISTRATION_ID_KEY);
    return this.httpClient.post('https://int-api.thehartford.com/authorize?client_id=1009&target_env=LTD2&0.31664935270723693&responsetype=ssotoken&channelid=1', request, {headers});
  }
}
