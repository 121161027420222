<div class="input-container {{preRenderingLoad?'skeleton-container':'skeleton-container removed'}} {{inputType == 'password' ? 'input-with-icon' : ''}}"
     [style.width.%]="inputSize"
  >
  <input
    *ngIf="inputType != 'tel'"
    [type]="inputType"
    [placeholder]="inputPlaceholder"
    [id]="inputId"
    [name]="inputId"
    [attr.maxlength]="maxlength"
    [attr.minlength]="minlength"
    [attr.max]="max"
    class=" {{unmaskedClass}}
  {{inputType == 'password'?'password-input-class':''}}
  {{focusCondition?'focus-class':''}}
  {{errorCondition?'error-class':''}}
  {{readOnlyCondition?'read-only-class':''}}"
    #input
    [ngModel]="inputValue"

    [readOnly]="readOnlyCondition"
    [attr.aria-label] ="ariaLabel"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown.space)="onSpaceEnter($event)"
    (change)="hasChanged()"
    elementPropertyDirective
    />

  <input
    *ngIf="inputType == 'tel'"
    [type]="inputType"
    [placeholder]="inputPlaceholder"
    [id]="inputId"
    [name]="inputId"
    [attr.maxlength]="maxlength"
    [attr.minlength]="minlength"
    [attr.max]="max"
    class="
  {{inputType == 'password'?'password-input-class':''}}
  {{focusCondition?'focus-class':''}}
  {{errorCondition?'error-class':''}}
  {{readOnlyCondition?'read-only-class':''}}"
    #input
    [ngModel]="inputValue"
    [readOnly]="readOnlyCondition"
    [attr.aria-label] ="ariaLabel"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (change)="hasChanged()"
    [attr.textMask]="getMask()"
    elementPropertyDirective
    />
  <a class="icon" *ngIf="inputType == 'password'" (mousedown)="togglePassword()" (mouseup)="getMouseUp()"
     (mouseleave)="getMouseUp()" (touchstart)="togglePassword()" (touchend)="getMouseUp()" (oncontextmenu)="disableSaveImage($event)">
    <div class="pass-icon" ></div>
  </a>
</div>
